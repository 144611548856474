/*
 * Aura v1.0 (https://registria.com/)
 * Copyright 2019 Registria
*/
/* LG Text Color */
/* LG Text Color */
/* LG Text Color */
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #FFF;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #f8991d;
  --secondary: #93a9b4;
  --success: #57c29d;
  --info: #577dc2;
  --warning: #c29c57;
  --danger: #c26757;
  --light: #b0bec5;
  --dark: #374447;
  --primary-2: #3f96b4;
  --dark-blue: #00758D;
  --lighter: #cfd8dc;
  --lightest: #eff2f3;
  --white: #FFF;
  --faint: #f7f9f9;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Montserrat", "Open Sans", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Montserrat", "Open Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #F1F4F5; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #f8991d;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #c27106;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2rem; }

h2, .h2 {
  font-size: 1.75rem; }

h3, .h3 {
  font-size: 1.5rem; }

h4, .h4 {
  font-size: 1.25rem; }

h5, .h5 {
  font-size: 1.125rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #F1F4F5;
  border: 1px solid #dee2e6;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #FFF;
  background-color: #212529; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 767.98px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 991.96px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 991.97px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 991.98px; } }

.container-fluid {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto; }

.row, .aura-content-container {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #fde2c0; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #fbca89; }

.table-hover .table-primary:hover {
  background-color: #fcd7a7; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #fcd7a7; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #e1e7ea; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #c7d2d8; }

.table-hover .table-secondary:hover {
  background-color: #d2dbe0; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #d2dbe0; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #d0eee4; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #a8dfcc; }

.table-hover .table-success:hover {
  background-color: #bde7d9; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #bde7d9; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #d0dbee; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #a8bbdf; }

.table-hover .table-info:hover {
  background-color: #bdcde7; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #bdcde7; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #eee3d0; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #dfcca8; }

.table-hover .table-warning:hover {
  background-color: #e7d8bd; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #e7d8bd; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #eed4d0; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #dfb0a8; }

.table-hover .table-danger:hover {
  background-color: #e7c3bd; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #e7c3bd; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #e9edef; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #d6dde1; }

.table-hover .table-light:hover {
  background-color: #dae1e4; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #dae1e4; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c7cbcb; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #979e9f; }

.table-hover .table-dark:hover {
  background-color: #babfbf; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #babfbf; }

.table-primary-2,
.table-primary-2 > th,
.table-primary-2 > td {
  background-color: #c9e2ea; }

.table-primary-2 th,
.table-primary-2 td,
.table-primary-2 thead th,
.table-primary-2 tbody + tbody {
  border-color: #9bc8d8; }

.table-hover .table-primary-2:hover {
  background-color: #b7d8e3; }
  .table-hover .table-primary-2:hover > td,
  .table-hover .table-primary-2:hover > th {
    background-color: #b7d8e3; }

.table-dark-blue,
.table-dark-blue > th,
.table-dark-blue > td {
  background-color: #b8d8df; }

.table-dark-blue th,
.table-dark-blue td,
.table-dark-blue thead th,
.table-dark-blue tbody + tbody {
  border-color: #7ab7c4; }

.table-hover .table-dark-blue:hover {
  background-color: #a6ced7; }
  .table-hover .table-dark-blue:hover > td,
  .table-hover .table-dark-blue:hover > th {
    background-color: #a6ced7; }

.table-lighter,
.table-lighter > th,
.table-lighter > td {
  background-color: #f2f4f5; }

.table-lighter th,
.table-lighter td,
.table-lighter thead th,
.table-lighter tbody + tbody {
  border-color: #e6ebed; }

.table-hover .table-lighter:hover {
  background-color: #e4e8ea; }
  .table-hover .table-lighter:hover > td,
  .table-hover .table-lighter:hover > th {
    background-color: #e4e8ea; }

.table-lightest,
.table-lightest > th,
.table-lightest > td {
  background-color: #fbfbfc; }

.table-lightest th,
.table-lightest td,
.table-lightest thead th,
.table-lightest tbody + tbody {
  border-color: #f7f8f9; }

.table-hover .table-lightest:hover {
  background-color: #ececf1; }
  .table-hover .table-lightest:hover > td,
  .table-hover .table-lightest:hover > th {
    background-color: #ececf1; }

.table-white,
.table-white > th,
.table-white > td {
  background-color: white; }

.table-white th,
.table-white td,
.table-white thead th,
.table-white tbody + tbody {
  border-color: white; }

.table-hover .table-white:hover {
  background-color: #f2f2f2; }
  .table-hover .table-white:hover > td,
  .table-hover .table-white:hover > th {
    background-color: #f2f2f2; }

.table-faint,
.table-faint > th,
.table-faint > td {
  background-color: #fdfdfd; }

.table-faint th,
.table-faint td,
.table-faint thead th,
.table-faint tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-faint:hover {
  background-color: #f0f0f0; }
  .table-hover .table-faint:hover > td,
  .table-hover .table-faint:hover > th {
    background-color: #f0f0f0; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #FFF;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #FFF;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #FFF;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control, .form-group .custom-select, .select2-container--default .select2-selection--single {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #FFF;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control, .form-group .custom-select, .select2-container--default .select2-selection--single {
      transition: none; } }
  .form-control::-ms-expand, .form-group .custom-select::-ms-expand, .select2-container--default .select2-selection--single::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus, .form-group .custom-select:focus, .select2-container--default .select2-selection--single:focus {
    color: #495057;
    background-color: #FFF;
    border-color: #fcd199;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 153, 29, 0.25); }
  .form-control::placeholder, .form-group .custom-select::placeholder, .select2-container--default .select2-selection--single::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-group .custom-select:disabled, .select2-container--default .select2-selection--single:disabled, .form-control[readonly], .form-group .custom-select[readonly], .select2-container--default .select2-selection--single[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value, .form-group select.custom-select:focus::-ms-value, .select2-container--default select.select2-selection--single:focus::-ms-value {
  color: #495057;
  background-color: #FFF; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5; }

select.form-control[size], .form-group select.custom-select[size], .select2-container--default select.select2-selection--single[size], select.form-control[multiple], .form-group select.custom-select[multiple], .select2-container--default select.select2-selection--single[multiple] {
  height: auto; }

textarea.form-control, .form-group textarea.custom-select, .select2-container--default textarea.select2-selection--single {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #57c29d; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #FFF;
  background-color: rgba(87, 194, 157, 0.9); }

.was-validated .form-control:valid, .was-validated .form-group .custom-select:valid, .form-group .was-validated .custom-select:valid, .was-validated .select2-container--default .select2-selection--single:valid, .select2-container--default .was-validated .select2-selection--single:valid, .form-control.is-valid, .form-group .is-valid.custom-select, .select2-container--default .is-valid.select2-selection--single {
  border-color: #57c29d;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2357c29d' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .was-validated .form-group .custom-select:valid:focus, .form-group .was-validated .custom-select:valid:focus, .was-validated .select2-container--default .select2-selection--single:valid:focus, .select2-container--default .was-validated .select2-selection--single:valid:focus, .form-control.is-valid:focus, .form-group .is-valid.custom-select:focus, .select2-container--default .is-valid.select2-selection--single:focus {
    border-color: #57c29d;
    box-shadow: 0 0 0 0.2rem rgba(87, 194, 157, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback, .was-validated .form-group .custom-select:valid ~ .valid-feedback, .form-group .was-validated .custom-select:valid ~ .valid-feedback, .was-validated .select2-container--default .select2-selection--single:valid ~ .valid-feedback, .select2-container--default .was-validated .select2-selection--single:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip,
  .was-validated .form-group .custom-select:valid ~ .valid-tooltip,
  .form-group .was-validated .custom-select:valid ~ .valid-tooltip,
  .was-validated .select2-container--default .select2-selection--single:valid ~ .valid-tooltip,
  .select2-container--default .was-validated .select2-selection--single:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback, .form-group .is-valid.custom-select ~ .valid-feedback, .select2-container--default .is-valid.select2-selection--single ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip,
  .form-group .is-valid.custom-select ~ .valid-tooltip,
  .select2-container--default .is-valid.select2-selection--single ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, .was-validated .form-group textarea.custom-select:valid, .form-group .was-validated textarea.custom-select:valid, .was-validated .select2-container--default textarea.select2-selection--single:valid, .select2-container--default .was-validated textarea.select2-selection--single:valid, textarea.form-control.is-valid, .form-group textarea.is-valid.custom-select, .select2-container--default textarea.is-valid.select2-selection--single {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #57c29d;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23374447' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2357c29d' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #FFF no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #57c29d;
    box-shadow: 0 0 0 0.2rem rgba(87, 194, 157, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #57c29d; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #57c29d; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #57c29d; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #7cd0b3;
  background-color: #7cd0b3; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(87, 194, 157, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #57c29d; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #57c29d; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #57c29d;
  box-shadow: 0 0 0 0.2rem rgba(87, 194, 157, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #c26757; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #FFF;
  background-color: rgba(194, 103, 87, 0.9); }

.was-validated .form-control:invalid, .was-validated .form-group .custom-select:invalid, .form-group .was-validated .custom-select:invalid, .was-validated .select2-container--default .select2-selection--single:invalid, .select2-container--default .was-validated .select2-selection--single:invalid, .form-control.is-invalid, .form-group .is-invalid.custom-select, .select2-container--default .is-invalid.select2-selection--single {
  border-color: #c26757;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23c26757' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23c26757' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .was-validated .form-group .custom-select:invalid:focus, .form-group .was-validated .custom-select:invalid:focus, .was-validated .select2-container--default .select2-selection--single:invalid:focus, .select2-container--default .was-validated .select2-selection--single:invalid:focus, .form-control.is-invalid:focus, .form-group .is-invalid.custom-select:focus, .select2-container--default .is-invalid.select2-selection--single:focus {
    border-color: #c26757;
    box-shadow: 0 0 0 0.2rem rgba(194, 103, 87, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .form-group .custom-select:invalid ~ .invalid-feedback, .form-group .was-validated .custom-select:invalid ~ .invalid-feedback, .was-validated .select2-container--default .select2-selection--single:invalid ~ .invalid-feedback, .select2-container--default .was-validated .select2-selection--single:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip,
  .was-validated .form-group .custom-select:invalid ~ .invalid-tooltip,
  .form-group .was-validated .custom-select:invalid ~ .invalid-tooltip,
  .was-validated .select2-container--default .select2-selection--single:invalid ~ .invalid-tooltip,
  .select2-container--default .was-validated .select2-selection--single:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback, .form-group .is-invalid.custom-select ~ .invalid-feedback, .select2-container--default .is-invalid.select2-selection--single ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip,
  .form-group .is-invalid.custom-select ~ .invalid-tooltip,
  .select2-container--default .is-invalid.select2-selection--single ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, .was-validated .form-group textarea.custom-select:invalid, .form-group .was-validated textarea.custom-select:invalid, .was-validated .select2-container--default textarea.select2-selection--single:invalid, .select2-container--default .was-validated textarea.select2-selection--single:invalid, textarea.form-control.is-invalid, .form-group textarea.is-invalid.custom-select, .select2-container--default textarea.is-invalid.select2-selection--single {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #c26757;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23374447' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23c26757' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23c26757' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #FFF no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #c26757;
    box-shadow: 0 0 0 0.2rem rgba(194, 103, 87, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #c26757; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #c26757; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #c26757; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #d0897c;
  background-color: #d0897c; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(194, 103, 87, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #c26757; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #c26757; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #c26757;
  box-shadow: 0 0 0 0.2rem rgba(194, 103, 87, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control, .form-inline .form-group .custom-select, .form-group .form-inline .custom-select, .form-inline .select2-container--default .select2-selection--single, .select2-container--default .form-inline .select2-selection--single {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn, .survey-scale .custom-control.custom-radio .custom-control-label, .markdown-body blockquote a {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn, .survey-scale .custom-control.custom-radio .custom-control-label, .markdown-body blockquote a {
      transition: none; } }
  .btn:hover, .survey-scale .custom-control.custom-radio .custom-control-label:hover, .markdown-body blockquote a:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .survey-scale .custom-control.custom-radio .custom-control-label:focus, .markdown-body blockquote a:focus, .btn.focus, .survey-scale .custom-control.custom-radio .focus.custom-control-label, .markdown-body blockquote a.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 153, 29, 0.25); }
  .btn.disabled, .survey-scale .custom-control.custom-radio .disabled.custom-control-label, .markdown-body blockquote a.disabled, .btn:disabled, .survey-scale .custom-control.custom-radio .custom-control-label:disabled, .markdown-body blockquote a:disabled {
    opacity: 0.65; }

a.btn.disabled, .survey-scale .custom-control.custom-radio a.disabled.custom-control-label, .markdown-body blockquote a.disabled,
fieldset:disabled a.btn,
fieldset:disabled .survey-scale .custom-control.custom-radio a.custom-control-label,
.survey-scale .custom-control.custom-radio fieldset:disabled a.custom-control-label,
fieldset:disabled .markdown-body blockquote a,
.markdown-body blockquote fieldset:disabled a {
  pointer-events: none; }

.btn-primary, .markdown-body blockquote a {
  color: #FFF;
  background-color: #f8991d;
  border-color: #f8991d; }
  .btn-primary:hover, .markdown-body blockquote a:hover {
    color: #FFF;
    background-color: #e88607;
    border-color: #db7f07; }
  .btn-primary:focus, .markdown-body blockquote a:focus, .btn-primary.focus, .markdown-body blockquote a.focus {
    box-shadow: 0 0 0 0.2rem rgba(249, 168, 63, 0.5); }
  .btn-primary.disabled, .markdown-body blockquote a.disabled, .btn-primary:disabled, .markdown-body blockquote a:disabled {
    color: #FFF;
    background-color: #f8991d;
    border-color: #f8991d; }
  .btn-primary:not(:disabled):not(.disabled):active, .markdown-body blockquote a:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .markdown-body blockquote a:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle,
  .markdown-body blockquote .show > a.dropdown-toggle {
    color: #FFF;
    background-color: #db7f07;
    border-color: #cf7806; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .markdown-body blockquote a:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .markdown-body blockquote a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus,
    .markdown-body blockquote .show > a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(249, 168, 63, 0.5); }

.btn-secondary {
  color: #FFF;
  background-color: #93a9b4;
  border-color: #93a9b4; }
  .btn-secondary:hover {
    color: #FFF;
    background-color: #7c97a4;
    border-color: #75919f; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(163, 182, 191, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #FFF;
    background-color: #93a9b4;
    border-color: #93a9b4; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #FFF;
    background-color: #75919f;
    border-color: #6d8b9a; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(163, 182, 191, 0.5); }

.btn-success {
  color: #FFF;
  background-color: #57c29d;
  border-color: #57c29d; }
  .btn-success:hover {
    color: #FFF;
    background-color: #41b28b;
    border-color: #3da984; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(112, 203, 172, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #FFF;
    background-color: #57c29d;
    border-color: #57c29d; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #FFF;
    background-color: #3da984;
    border-color: #3a9f7c; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(112, 203, 172, 0.5); }

.btn-info {
  color: #FFF;
  background-color: #577dc2;
  border-color: #577dc2; }
  .btn-info:hover {
    color: #FFF;
    background-color: #4169b2;
    border-color: #3d63a9; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(112, 145, 203, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #FFF;
    background-color: #577dc2;
    border-color: #577dc2; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #FFF;
    background-color: #3d63a9;
    border-color: #3a5e9f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(112, 145, 203, 0.5); }

.btn-warning {
  color: #FFF;
  background-color: #c29c57;
  border-color: #c29c57; }
  .btn-warning:hover {
    color: #FFF;
    background-color: #b28a41;
    border-color: #a9833d; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(203, 171, 112, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #FFF;
    background-color: #c29c57;
    border-color: #c29c57; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #FFF;
    background-color: #a9833d;
    border-color: #9f7b3a; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(203, 171, 112, 0.5); }

.btn-danger {
  color: #FFF;
  background-color: #c26757;
  border-color: #c26757; }
  .btn-danger:hover {
    color: #FFF;
    background-color: #b25241;
    border-color: #a94d3d; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(203, 126, 112, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #FFF;
    background-color: #c26757;
    border-color: #c26757; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #FFF;
    background-color: #a94d3d;
    border-color: #9f493a; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(203, 126, 112, 0.5); }

.btn-light {
  color: #FFF;
  background-color: #b0bec5;
  border-color: #b0bec5; }
  .btn-light:hover {
    color: #FFF;
    background-color: #9aacb5;
    border-color: #93a6af; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(188, 200, 206, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #FFF;
    background-color: #b0bec5;
    border-color: #b0bec5; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #FFF;
    background-color: #93a6af;
    border-color: #8ba0aa; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(188, 200, 206, 0.5); }

.btn-dark {
  color: #FFF;
  background-color: #374447;
  border-color: #374447; }
  .btn-dark:hover {
    color: #FFF;
    background-color: #262f31;
    border-color: #21282a; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(85, 96, 99, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #FFF;
    background-color: #374447;
    border-color: #374447; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #FFF;
    background-color: #21282a;
    border-color: #1b2223; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(85, 96, 99, 0.5); }

.btn-primary-2 {
  color: #FFF;
  background-color: #3f96b4;
  border-color: #3f96b4; }
  .btn-primary-2:hover {
    color: #FFF;
    background-color: #357e98;
    border-color: #32778e; }
  .btn-primary-2:focus, .btn-primary-2.focus {
    box-shadow: 0 0 0 0.2rem rgba(92, 166, 191, 0.5); }
  .btn-primary-2.disabled, .btn-primary-2:disabled {
    color: #FFF;
    background-color: #3f96b4;
    border-color: #3f96b4; }
  .btn-primary-2:not(:disabled):not(.disabled):active, .btn-primary-2:not(:disabled):not(.disabled).active,
  .show > .btn-primary-2.dropdown-toggle {
    color: #FFF;
    background-color: #32778e;
    border-color: #2e6f85; }
    .btn-primary-2:not(:disabled):not(.disabled):active:focus, .btn-primary-2:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary-2.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(92, 166, 191, 0.5); }

.btn-dark-blue {
  color: #FFF;
  background-color: #00758D;
  border-color: #00758D; }
  .btn-dark-blue:hover {
    color: #FFF;
    background-color: #005567;
    border-color: #004b5a; }
  .btn-dark-blue:focus, .btn-dark-blue.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 138, 158, 0.5); }
  .btn-dark-blue.disabled, .btn-dark-blue:disabled {
    color: #FFF;
    background-color: #00758D;
    border-color: #00758D; }
  .btn-dark-blue:not(:disabled):not(.disabled):active, .btn-dark-blue:not(:disabled):not(.disabled).active,
  .show > .btn-dark-blue.dropdown-toggle {
    color: #FFF;
    background-color: #004b5a;
    border-color: #00404d; }
    .btn-dark-blue:not(:disabled):not(.disabled):active:focus, .btn-dark-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 138, 158, 0.5); }

.btn-lighter {
  color: #212529;
  background-color: #cfd8dc;
  border-color: #cfd8dc; }
  .btn-lighter:hover {
    color: #FFF;
    background-color: #b9c6cc;
    border-color: #b2c0c6; }
  .btn-lighter:focus, .btn-lighter.focus {
    box-shadow: 0 0 0 0.2rem rgba(181, 189, 193, 0.5); }
  .btn-lighter.disabled, .btn-lighter:disabled {
    color: #212529;
    background-color: #cfd8dc;
    border-color: #cfd8dc; }
  .btn-lighter:not(:disabled):not(.disabled):active, .btn-lighter:not(:disabled):not(.disabled).active,
  .show > .btn-lighter.dropdown-toggle {
    color: #FFF;
    background-color: #b2c0c6;
    border-color: #aabac1; }
    .btn-lighter:not(:disabled):not(.disabled):active:focus, .btn-lighter:not(:disabled):not(.disabled).active:focus,
    .show > .btn-lighter.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(181, 189, 193, 0.5); }

.btn-lightest {
  color: #212529;
  background-color: #eff2f3;
  border-color: #eff2f3; }
  .btn-lightest:hover {
    color: #212529;
    background-color: #d9e0e3;
    border-color: #d2dadd; }
  .btn-lightest:focus, .btn-lightest.focus {
    box-shadow: 0 0 0 0.2rem rgba(208, 211, 213, 0.5); }
  .btn-lightest.disabled, .btn-lightest:disabled {
    color: #212529;
    background-color: #eff2f3;
    border-color: #eff2f3; }
  .btn-lightest:not(:disabled):not(.disabled):active, .btn-lightest:not(:disabled):not(.disabled).active,
  .show > .btn-lightest.dropdown-toggle {
    color: #212529;
    background-color: #d2dadd;
    border-color: #cbd4d8; }
    .btn-lightest:not(:disabled):not(.disabled):active:focus, .btn-lightest:not(:disabled):not(.disabled).active:focus,
    .show > .btn-lightest.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(208, 211, 213, 0.5); }

.btn-white {
  color: #212529;
  background-color: #FFF;
  border-color: #FFF; }
  .btn-white:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-white:focus, .btn-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }
  .btn-white.disabled, .btn-white:disabled {
    color: #212529;
    background-color: #FFF;
    border-color: #FFF; }
  .btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
  .show > .btn-white.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }

.btn-faint {
  color: #212529;
  background-color: #f7f9f9;
  border-color: #f7f9f9; }
  .btn-faint:hover {
    color: #212529;
    background-color: #e1e9e9;
    border-color: #dae3e3; }
  .btn-faint:focus, .btn-faint.focus {
    box-shadow: 0 0 0 0.2rem rgba(215, 217, 218, 0.5); }
  .btn-faint.disabled, .btn-faint:disabled {
    color: #212529;
    background-color: #f7f9f9;
    border-color: #f7f9f9; }
  .btn-faint:not(:disabled):not(.disabled):active, .btn-faint:not(:disabled):not(.disabled).active,
  .show > .btn-faint.dropdown-toggle {
    color: #212529;
    background-color: #dae3e3;
    border-color: #d3dede; }
    .btn-faint:not(:disabled):not(.disabled):active:focus, .btn-faint:not(:disabled):not(.disabled).active:focus,
    .show > .btn-faint.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(215, 217, 218, 0.5); }

.btn-outline-primary, .survey-scale .custom-control.custom-radio .custom-control-label {
  color: #f8991d;
  border-color: #f8991d; }
  .btn-outline-primary:hover, .survey-scale .custom-control.custom-radio .custom-control-label:hover {
    color: #FFF;
    background-color: #f8991d;
    border-color: #f8991d; }
  .btn-outline-primary:focus, .survey-scale .custom-control.custom-radio .custom-control-label:focus, .btn-outline-primary.focus, .survey-scale .custom-control.custom-radio .focus.custom-control-label {
    box-shadow: 0 0 0 0.2rem rgba(248, 153, 29, 0.5); }
  .btn-outline-primary.disabled, .survey-scale .custom-control.custom-radio .disabled.custom-control-label, .btn-outline-primary:disabled, .survey-scale .custom-control.custom-radio .custom-control-label:disabled {
    color: #f8991d;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .survey-scale .custom-control.custom-radio .custom-control-label:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .survey-scale .custom-control.custom-radio .custom-control-label:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle,
  .survey-scale .custom-control.custom-radio .show > .dropdown-toggle.custom-control-label {
    color: #FFF;
    background-color: #f8991d;
    border-color: #f8991d; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .survey-scale .custom-control.custom-radio .custom-control-label:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .survey-scale .custom-control.custom-radio .custom-control-label:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus,
    .survey-scale .custom-control.custom-radio .show > .dropdown-toggle.custom-control-label:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 153, 29, 0.5); }

.btn-outline-secondary {
  color: #93a9b4;
  border-color: #93a9b4; }
  .btn-outline-secondary:hover {
    color: #FFF;
    background-color: #93a9b4;
    border-color: #93a9b4; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(147, 169, 180, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #93a9b4;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #FFF;
    background-color: #93a9b4;
    border-color: #93a9b4; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(147, 169, 180, 0.5); }

.btn-outline-success {
  color: #57c29d;
  border-color: #57c29d; }
  .btn-outline-success:hover {
    color: #FFF;
    background-color: #57c29d;
    border-color: #57c29d; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(87, 194, 157, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #57c29d;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #FFF;
    background-color: #57c29d;
    border-color: #57c29d; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(87, 194, 157, 0.5); }

.btn-outline-info {
  color: #577dc2;
  border-color: #577dc2; }
  .btn-outline-info:hover {
    color: #FFF;
    background-color: #577dc2;
    border-color: #577dc2; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(87, 125, 194, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #577dc2;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #FFF;
    background-color: #577dc2;
    border-color: #577dc2; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(87, 125, 194, 0.5); }

.btn-outline-warning {
  color: #c29c57;
  border-color: #c29c57; }
  .btn-outline-warning:hover {
    color: #FFF;
    background-color: #c29c57;
    border-color: #c29c57; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(194, 156, 87, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #c29c57;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #FFF;
    background-color: #c29c57;
    border-color: #c29c57; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(194, 156, 87, 0.5); }

.btn-outline-danger {
  color: #c26757;
  border-color: #c26757; }
  .btn-outline-danger:hover {
    color: #FFF;
    background-color: #c26757;
    border-color: #c26757; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(194, 103, 87, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #c26757;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #FFF;
    background-color: #c26757;
    border-color: #c26757; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(194, 103, 87, 0.5); }

.btn-outline-light {
  color: #b0bec5;
  border-color: #b0bec5; }
  .btn-outline-light:hover {
    color: #FFF;
    background-color: #b0bec5;
    border-color: #b0bec5; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(176, 190, 197, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #b0bec5;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #FFF;
    background-color: #b0bec5;
    border-color: #b0bec5; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(176, 190, 197, 0.5); }

.btn-outline-dark {
  color: #374447;
  border-color: #374447; }
  .btn-outline-dark:hover {
    color: #FFF;
    background-color: #374447;
    border-color: #374447; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(55, 68, 71, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #374447;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #FFF;
    background-color: #374447;
    border-color: #374447; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(55, 68, 71, 0.5); }

.btn-outline-primary-2 {
  color: #3f96b4;
  border-color: #3f96b4; }
  .btn-outline-primary-2:hover {
    color: #FFF;
    background-color: #3f96b4;
    border-color: #3f96b4; }
  .btn-outline-primary-2:focus, .btn-outline-primary-2.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 150, 180, 0.5); }
  .btn-outline-primary-2.disabled, .btn-outline-primary-2:disabled {
    color: #3f96b4;
    background-color: transparent; }
  .btn-outline-primary-2:not(:disabled):not(.disabled):active, .btn-outline-primary-2:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary-2.dropdown-toggle {
    color: #FFF;
    background-color: #3f96b4;
    border-color: #3f96b4; }
    .btn-outline-primary-2:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-2:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary-2.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(63, 150, 180, 0.5); }

.btn-outline-dark-blue {
  color: #00758D;
  border-color: #00758D; }
  .btn-outline-dark-blue:hover {
    color: #FFF;
    background-color: #00758D;
    border-color: #00758D; }
  .btn-outline-dark-blue:focus, .btn-outline-dark-blue.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 117, 141, 0.5); }
  .btn-outline-dark-blue.disabled, .btn-outline-dark-blue:disabled {
    color: #00758D;
    background-color: transparent; }
  .btn-outline-dark-blue:not(:disabled):not(.disabled):active, .btn-outline-dark-blue:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark-blue.dropdown-toggle {
    color: #FFF;
    background-color: #00758D;
    border-color: #00758D; }
    .btn-outline-dark-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-dark-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 117, 141, 0.5); }

.btn-outline-lighter {
  color: #cfd8dc;
  border-color: #cfd8dc; }
  .btn-outline-lighter:hover {
    color: #212529;
    background-color: #cfd8dc;
    border-color: #cfd8dc; }
  .btn-outline-lighter:focus, .btn-outline-lighter.focus {
    box-shadow: 0 0 0 0.2rem rgba(207, 216, 220, 0.5); }
  .btn-outline-lighter.disabled, .btn-outline-lighter:disabled {
    color: #cfd8dc;
    background-color: transparent; }
  .btn-outline-lighter:not(:disabled):not(.disabled):active, .btn-outline-lighter:not(:disabled):not(.disabled).active,
  .show > .btn-outline-lighter.dropdown-toggle {
    color: #212529;
    background-color: #cfd8dc;
    border-color: #cfd8dc; }
    .btn-outline-lighter:not(:disabled):not(.disabled):active:focus, .btn-outline-lighter:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-lighter.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(207, 216, 220, 0.5); }

.btn-outline-lightest {
  color: #eff2f3;
  border-color: #eff2f3; }
  .btn-outline-lightest:hover {
    color: #212529;
    background-color: #eff2f3;
    border-color: #eff2f3; }
  .btn-outline-lightest:focus, .btn-outline-lightest.focus {
    box-shadow: 0 0 0 0.2rem rgba(239, 242, 243, 0.5); }
  .btn-outline-lightest.disabled, .btn-outline-lightest:disabled {
    color: #eff2f3;
    background-color: transparent; }
  .btn-outline-lightest:not(:disabled):not(.disabled):active, .btn-outline-lightest:not(:disabled):not(.disabled).active,
  .show > .btn-outline-lightest.dropdown-toggle {
    color: #212529;
    background-color: #eff2f3;
    border-color: #eff2f3; }
    .btn-outline-lightest:not(:disabled):not(.disabled):active:focus, .btn-outline-lightest:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-lightest.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(239, 242, 243, 0.5); }

.btn-outline-white {
  color: #FFF;
  border-color: #FFF; }
  .btn-outline-white:hover {
    color: #212529;
    background-color: #FFF;
    border-color: #FFF; }
  .btn-outline-white:focus, .btn-outline-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-white.disabled, .btn-outline-white:disabled {
    color: #FFF;
    background-color: transparent; }
  .btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-white.dropdown-toggle {
    color: #212529;
    background-color: #FFF;
    border-color: #FFF; }
    .btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-outline-faint {
  color: #f7f9f9;
  border-color: #f7f9f9; }
  .btn-outline-faint:hover {
    color: #212529;
    background-color: #f7f9f9;
    border-color: #f7f9f9; }
  .btn-outline-faint:focus, .btn-outline-faint.focus {
    box-shadow: 0 0 0 0.2rem rgba(247, 249, 249, 0.5); }
  .btn-outline-faint.disabled, .btn-outline-faint:disabled {
    color: #f7f9f9;
    background-color: transparent; }
  .btn-outline-faint:not(:disabled):not(.disabled):active, .btn-outline-faint:not(:disabled):not(.disabled).active,
  .show > .btn-outline-faint.dropdown-toggle {
    color: #212529;
    background-color: #f7f9f9;
    border-color: #f7f9f9; }
    .btn-outline-faint:not(:disabled):not(.disabled):active:focus, .btn-outline-faint:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-faint.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(247, 249, 249, 0.5); }

.btn-link {
  font-weight: 400;
  color: #f8991d;
  text-decoration: none; }
  .btn-link:hover {
    color: #c27106;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn, .survey-scale .custom-control.custom-radio .btn-group-lg > .custom-control-label, .markdown-body blockquote .btn-group-lg > a {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-sm, .btn-group-sm > .btn, .survey-scale .custom-control.custom-radio .btn-group-sm > .custom-control-label, .markdown-body blockquote .btn-group-sm > a {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu, .frauto-list {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #FFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15); }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu, .dropup .frauto-list {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu, .dropright .frauto-list {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu, .dropleft .frauto-list {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .frauto-list[x-placement^="top"], .dropdown-menu[x-placement^="right"], .frauto-list[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .frauto-list[x-placement^="bottom"], .dropdown-menu[x-placement^="left"], .frauto-list[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item, .frauto-list li {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .frauto-list li:hover, .dropdown-item:focus, .frauto-list li:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .frauto-list li.active, .dropdown-item:active, .frauto-list li:active {
    color: #FFF;
    text-decoration: none;
    background-color: #f8991d; }
  .dropdown-item.disabled, .frauto-list li.disabled, .dropdown-item:disabled, .frauto-list li:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show, .show.frauto-list {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn, .survey-scale .custom-control.custom-radio .btn-group > .custom-control-label, .markdown-body blockquote .btn-group > a,
  .btn-group-vertical > .btn,
  .survey-scale .custom-control.custom-radio .btn-group-vertical > .custom-control-label,
  .markdown-body blockquote .btn-group-vertical > a {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover, .survey-scale .custom-control.custom-radio .btn-group > .custom-control-label:hover, .markdown-body blockquote .btn-group > a:hover,
    .btn-group-vertical > .btn:hover,
    .survey-scale .custom-control.custom-radio .btn-group-vertical > .custom-control-label:hover,
    .markdown-body blockquote .btn-group-vertical > a:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .survey-scale .custom-control.custom-radio .btn-group > .custom-control-label:focus, .markdown-body blockquote .btn-group > a:focus, .btn-group > .btn:active, .survey-scale .custom-control.custom-radio .btn-group > .custom-control-label:active, .markdown-body blockquote .btn-group > a:active, .btn-group > .btn.active, .survey-scale .custom-control.custom-radio .btn-group > .active.custom-control-label, .markdown-body blockquote .btn-group > a.active,
    .btn-group-vertical > .btn:focus,
    .survey-scale .custom-control.custom-radio .btn-group-vertical > .custom-control-label:focus,
    .markdown-body blockquote .btn-group-vertical > a:focus,
    .btn-group-vertical > .btn:active,
    .survey-scale .custom-control.custom-radio .btn-group-vertical > .custom-control-label:active,
    .markdown-body blockquote .btn-group-vertical > a:active,
    .btn-group-vertical > .btn.active,
    .survey-scale .custom-control.custom-radio .btn-group-vertical > .active.custom-control-label,
    .markdown-body blockquote .btn-group-vertical > a.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child), .survey-scale .custom-control.custom-radio .btn-group > .custom-control-label:not(:first-child), .markdown-body blockquote .btn-group > a:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .survey-scale .custom-control.custom-radio .btn-group-sm > .custom-control-label + .dropdown-toggle-split, .markdown-body blockquote .btn-group-sm > a + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .survey-scale .custom-control.custom-radio .btn-group-lg > .custom-control-label + .dropdown-toggle-split, .markdown-body blockquote .btn-group-lg > a + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  
  .btn-group-vertical > .btn,
  .survey-scale .custom-control.custom-radio .btn-group-vertical > .custom-control-label,
  .markdown-body blockquote .btn-group-vertical > a,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child), .survey-scale .custom-control.custom-radio .btn-group-vertical > .custom-control-label:not(:first-child), .markdown-body blockquote .btn-group-vertical > a:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }

.btn-group-toggle > .btn, .survey-scale .custom-control.custom-radio .btn-group-toggle > .custom-control-label, .markdown-body blockquote .btn-group-toggle > a,
.btn-group-toggle > .btn-group > .btn,
.survey-scale .custom-control.custom-radio .btn-group-toggle > .btn-group > .custom-control-label,
.markdown-body blockquote .btn-group-toggle > .btn-group > a {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"], .survey-scale .custom-control.custom-radio .btn-group-toggle > .custom-control-label input[type="radio"], .markdown-body blockquote .btn-group-toggle > a input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .survey-scale .custom-control.custom-radio .btn-group-toggle > .custom-control-label input[type="checkbox"],
  .markdown-body blockquote .btn-group-toggle > a input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .survey-scale .custom-control.custom-radio .btn-group-toggle > .btn-group > .custom-control-label input[type="radio"],
  .markdown-body blockquote .btn-group-toggle > .btn-group > a input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"],
  .survey-scale .custom-control.custom-radio .btn-group-toggle > .btn-group > .custom-control-label input[type="checkbox"],
  .markdown-body blockquote .btn-group-toggle > .btn-group > a input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control, .form-group .input-group > .custom-select, .select2-container--default .input-group > .select2-selection--single,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control, .form-group .input-group > .custom-select + .form-control, .select2-container--default .input-group > .select2-selection--single + .form-control, .form-group .input-group > .form-control + .custom-select, .form-group .input-group > .custom-select + .custom-select, .select2-container--default .form-group .input-group > .select2-selection--single + .custom-select, .form-group .select2-container--default .input-group > .select2-selection--single + .custom-select, .select2-container--default .input-group > .form-control + .select2-selection--single, .form-group .select2-container--default .input-group > .custom-select + .select2-selection--single, .select2-container--default .form-group .input-group > .custom-select + .select2-selection--single, .select2-container--default .input-group > .select2-selection--single + .select2-selection--single,
    .input-group > .form-control + .custom-select,
    .form-group .input-group > .custom-select + .custom-select,
    .select2-container--default .input-group > .select2-selection--single + .custom-select,
    .input-group > .form-control + .custom-file,
    .form-group .input-group > .custom-select + .custom-file,
    .select2-container--default .input-group > .select2-selection--single + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .form-group .input-group > .form-control-plaintext + .custom-select,
    .select2-container--default .input-group > .form-control-plaintext + .select2-selection--single,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .form-group .input-group > .custom-select + .custom-select,
    .select2-container--default .input-group > .custom-select + .select2-selection--single,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .form-group .input-group > .custom-file + .custom-select,
    .select2-container--default .input-group > .custom-file + .select2-selection--single,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus, .form-group .input-group > .custom-select:focus, .select2-container--default .input-group > .select2-selection--single:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn, .input-group-prepend .survey-scale .custom-control.custom-radio .custom-control-label, .survey-scale .custom-control.custom-radio .input-group-prepend .custom-control-label, .input-group-prepend .markdown-body blockquote a, .markdown-body blockquote .input-group-prepend a,
  .input-group-append .btn,
  .input-group-append .survey-scale .custom-control.custom-radio .custom-control-label,
  .survey-scale .custom-control.custom-radio .input-group-append .custom-control-label,
  .input-group-append .markdown-body blockquote a,
  .markdown-body blockquote .input-group-append a {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus, .input-group-prepend .survey-scale .custom-control.custom-radio .custom-control-label:focus, .survey-scale .custom-control.custom-radio .input-group-prepend .custom-control-label:focus, .input-group-prepend .markdown-body blockquote a:focus, .markdown-body blockquote .input-group-prepend a:focus,
    .input-group-append .btn:focus,
    .input-group-append .survey-scale .custom-control.custom-radio .custom-control-label:focus,
    .survey-scale .custom-control.custom-radio .input-group-append .custom-control-label:focus,
    .input-group-append .markdown-body blockquote a:focus,
    .markdown-body blockquote .input-group-append a:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn, .input-group-prepend .survey-scale .custom-control.custom-radio .custom-control-label + .btn, .survey-scale .custom-control.custom-radio .input-group-prepend .custom-control-label + .btn, .input-group-prepend .markdown-body blockquote a + .btn, .markdown-body blockquote .input-group-prepend a + .btn, .input-group-prepend .survey-scale .custom-control.custom-radio .btn + .custom-control-label, .survey-scale .custom-control.custom-radio .input-group-prepend .btn + .custom-control-label, .input-group-prepend .survey-scale .custom-control.custom-radio .custom-control-label + .custom-control-label, .survey-scale .custom-control.custom-radio .input-group-prepend .custom-control-label + .custom-control-label, .input-group-prepend .markdown-body blockquote .survey-scale .custom-control.custom-radio a + .custom-control-label, .survey-scale .custom-control.custom-radio .input-group-prepend .markdown-body blockquote a + .custom-control-label, .markdown-body blockquote .input-group-prepend .survey-scale .custom-control.custom-radio a + .custom-control-label, .survey-scale .custom-control.custom-radio .markdown-body blockquote .input-group-prepend a + .custom-control-label, .input-group-prepend .markdown-body blockquote .btn + a, .markdown-body blockquote .input-group-prepend .btn + a, .input-group-prepend .survey-scale .custom-control.custom-radio .markdown-body blockquote .custom-control-label + a, .markdown-body blockquote .input-group-prepend .survey-scale .custom-control.custom-radio .custom-control-label + a, .survey-scale .custom-control.custom-radio .input-group-prepend .markdown-body blockquote .custom-control-label + a, .markdown-body blockquote .survey-scale .custom-control.custom-radio .input-group-prepend .custom-control-label + a, .input-group-prepend .markdown-body blockquote a + a, .markdown-body blockquote .input-group-prepend a + a,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .survey-scale .custom-control.custom-radio .custom-control-label + .input-group-text,
  .survey-scale .custom-control.custom-radio .input-group-prepend .custom-control-label + .input-group-text,
  .input-group-prepend .markdown-body blockquote a + .input-group-text,
  .markdown-body blockquote .input-group-prepend a + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-prepend .survey-scale .custom-control.custom-radio .input-group-text + .custom-control-label,
  .survey-scale .custom-control.custom-radio .input-group-prepend .input-group-text + .custom-control-label,
  .input-group-prepend .markdown-body blockquote .input-group-text + a,
  .markdown-body blockquote .input-group-prepend .input-group-text + a,
  .input-group-append .btn + .btn,
  .input-group-append .survey-scale .custom-control.custom-radio .custom-control-label + .btn,
  .survey-scale .custom-control.custom-radio .input-group-append .custom-control-label + .btn,
  .input-group-append .markdown-body blockquote a + .btn,
  .markdown-body blockquote .input-group-append a + .btn,
  .input-group-append .survey-scale .custom-control.custom-radio .btn + .custom-control-label,
  .survey-scale .custom-control.custom-radio .input-group-append .btn + .custom-control-label,
  .input-group-append .survey-scale .custom-control.custom-radio .custom-control-label + .custom-control-label,
  .survey-scale .custom-control.custom-radio .input-group-append .custom-control-label + .custom-control-label,
  .input-group-append .markdown-body blockquote .survey-scale .custom-control.custom-radio a + .custom-control-label,
  .survey-scale .custom-control.custom-radio .input-group-append .markdown-body blockquote a + .custom-control-label,
  .markdown-body blockquote .input-group-append .survey-scale .custom-control.custom-radio a + .custom-control-label,
  .survey-scale .custom-control.custom-radio .markdown-body blockquote .input-group-append a + .custom-control-label,
  .input-group-append .markdown-body blockquote .btn + a,
  .markdown-body blockquote .input-group-append .btn + a,
  .input-group-append .survey-scale .custom-control.custom-radio .markdown-body blockquote .custom-control-label + a,
  .markdown-body blockquote .input-group-append .survey-scale .custom-control.custom-radio .custom-control-label + a,
  .survey-scale .custom-control.custom-radio .input-group-append .markdown-body blockquote .custom-control-label + a,
  .markdown-body blockquote .survey-scale .custom-control.custom-radio .input-group-append .custom-control-label + a,
  .input-group-append .markdown-body blockquote a + a,
  .markdown-body blockquote .input-group-append a + a,
  .input-group-append .btn + .input-group-text,
  .input-group-append .survey-scale .custom-control.custom-radio .custom-control-label + .input-group-text,
  .survey-scale .custom-control.custom-radio .input-group-append .custom-control-label + .input-group-text,
  .input-group-append .markdown-body blockquote a + .input-group-text,
  .markdown-body blockquote .input-group-append a + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn,
  .input-group-append .survey-scale .custom-control.custom-radio .input-group-text + .custom-control-label,
  .survey-scale .custom-control.custom-radio .input-group-append .input-group-text + .custom-control-label,
  .input-group-append .markdown-body blockquote .input-group-text + a,
  .markdown-body blockquote .input-group-append .input-group-text + a {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea), .form-group .input-group-lg > .custom-select:not(textarea), .select2-container--default .input-group-lg > .select2-selection--single:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control, .form-group .input-group-lg > .custom-select, .select2-container--default .input-group-lg > .select2-selection--single,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.survey-scale .custom-control.custom-radio .input-group-lg > .input-group-prepend > .custom-control-label,
.markdown-body blockquote .input-group-lg > .input-group-prepend > a,
.input-group-lg > .input-group-append > .btn,
.survey-scale .custom-control.custom-radio .input-group-lg > .input-group-append > .custom-control-label,
.markdown-body blockquote .input-group-lg > .input-group-append > a {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.input-group-sm > .form-control:not(textarea), .form-group .input-group-sm > .custom-select:not(textarea), .select2-container--default .input-group-sm > .select2-selection--single:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control, .form-group .input-group-sm > .custom-select, .select2-container--default .input-group-sm > .select2-selection--single,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.survey-scale .custom-control.custom-radio .input-group-sm > .input-group-prepend > .custom-control-label,
.markdown-body blockquote .input-group-sm > .input-group-prepend > a,
.input-group-sm > .input-group-append > .btn,
.survey-scale .custom-control.custom-radio .input-group-sm > .input-group-append > .custom-control-label,
.markdown-body blockquote .input-group-sm > .input-group-append > a {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #FFF;
    border-color: #f8991d;
    background-color: #f8991d; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(248, 153, 29, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #fcd199; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #FFF;
    background-color: #fde7ca;
    border-color: #fde7ca; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #FFF;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23FFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #f8991d;
  background-color: #f8991d; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23FFF' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(248, 153, 29, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(248, 153, 29, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFF'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(248, 153, 29, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #FFF;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(248, 153, 29, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23374447' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #FFF;
  border: 1px solid #ced4da;
  border-radius: 0;
  appearance: none; }
  .custom-select:focus {
    border-color: #fcd199;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 153, 29, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #FFF; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #fcd199;
    box-shadow: 0 0 0 0.2rem rgba(248, 153, 29, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #FFF;
  border: 1px solid #ced4da; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #F1F4F5, 0 0 0 0.2rem rgba(248, 153, 29, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #F1F4F5, 0 0 0 0.2rem rgba(248, 153, 29, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #F1F4F5, 0 0 0 0.2rem rgba(248, 153, 29, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #f8991d;
    border: 0;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #fde7ca; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #f8991d;
    border: 0;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #fde7ca; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #f8991d;
    border: 0;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #fde7ca; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #F1F4F5;
    border-color: #dee2e6 #dee2e6 #F1F4F5; }
  .nav-tabs .dropdown-menu, .nav-tabs .frauto-list {
    margin-top: -1px; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #FFF;
  background-color: #f8991d; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu, .navbar-nav .frauto-list {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu, .navbar-expand-sm .navbar-nav .frauto-list {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu, .navbar-expand-md .navbar-nav .frauto-list {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu, .navbar-expand-lg .navbar-nav .frauto-list {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu, .navbar-expand-xl .navbar-nav .frauto-list {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu, .navbar-expand .navbar-nav .frauto-list {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #FFF; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #FFF; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #FFF; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #FFF; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #FFF; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: transparent;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%; }

.card-img-top {
  width: 100%; }

.card-img-bottom {
  width: 100%; }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 1rem; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -1rem;
      margin-left: -1rem; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 1rem;
        margin-bottom: 0;
        margin-left: 1rem; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 1rem; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #f8991d;
  background-color: #FFF;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #c27106;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 153, 29, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0; }

.page-item.active .page-link {
  z-index: 1;
  color: #FFF;
  background-color: #f8991d;
  border-color: #f8991d; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #FFF;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge, .survey-scale .custom-control.custom-radio .custom-control-label .badge, .markdown-body blockquote a .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em; }

.badge-primary {
  color: #FFF;
  background-color: #f8991d; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #FFF;
    background-color: #db7f07; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 153, 29, 0.5); }

.badge-secondary {
  color: #FFF;
  background-color: #93a9b4; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #FFF;
    background-color: #75919f; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(147, 169, 180, 0.5); }

.badge-success {
  color: #FFF;
  background-color: #57c29d; }
  a.badge-success:hover, a.badge-success:focus {
    color: #FFF;
    background-color: #3da984; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(87, 194, 157, 0.5); }

.badge-info {
  color: #FFF;
  background-color: #577dc2; }
  a.badge-info:hover, a.badge-info:focus {
    color: #FFF;
    background-color: #3d63a9; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(87, 125, 194, 0.5); }

.badge-warning {
  color: #FFF;
  background-color: #c29c57; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #FFF;
    background-color: #a9833d; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(194, 156, 87, 0.5); }

.badge-danger {
  color: #FFF;
  background-color: #c26757; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #FFF;
    background-color: #a94d3d; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(194, 103, 87, 0.5); }

.badge-light {
  color: #FFF;
  background-color: #b0bec5; }
  a.badge-light:hover, a.badge-light:focus {
    color: #FFF;
    background-color: #93a6af; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(176, 190, 197, 0.5); }

.badge-dark {
  color: #FFF;
  background-color: #374447; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #FFF;
    background-color: #21282a; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(55, 68, 71, 0.5); }

.badge-primary-2 {
  color: #FFF;
  background-color: #3f96b4; }
  a.badge-primary-2:hover, a.badge-primary-2:focus {
    color: #FFF;
    background-color: #32778e; }
  a.badge-primary-2:focus, a.badge-primary-2.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(63, 150, 180, 0.5); }

.badge-dark-blue {
  color: #FFF;
  background-color: #00758D; }
  a.badge-dark-blue:hover, a.badge-dark-blue:focus {
    color: #FFF;
    background-color: #004b5a; }
  a.badge-dark-blue:focus, a.badge-dark-blue.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 117, 141, 0.5); }

.badge-lighter {
  color: #212529;
  background-color: #cfd8dc; }
  a.badge-lighter:hover, a.badge-lighter:focus {
    color: #212529;
    background-color: #b2c0c6; }
  a.badge-lighter:focus, a.badge-lighter.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(207, 216, 220, 0.5); }

.badge-lightest {
  color: #212529;
  background-color: #eff2f3; }
  a.badge-lightest:hover, a.badge-lightest:focus {
    color: #212529;
    background-color: #d2dadd; }
  a.badge-lightest:focus, a.badge-lightest.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(239, 242, 243, 0.5); }

.badge-white {
  color: #212529;
  background-color: #FFF; }
  a.badge-white:hover, a.badge-white:focus {
    color: #212529;
    background-color: #e6e6e6; }
  a.badge-white:focus, a.badge-white.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.badge-faint {
  color: #212529;
  background-color: #f7f9f9; }
  a.badge-faint:hover, a.badge-faint:focus {
    color: #212529;
    background-color: #dae3e3; }
  a.badge-faint:focus, a.badge-faint.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(247, 249, 249, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #81500f;
  background-color: #feebd2;
  border-color: #fde2c0; }
  .alert-primary hr {
    border-top-color: #fcd7a7; }
  .alert-primary .alert-link {
    color: #53340a; }

.alert-secondary {
  color: #4c585e;
  background-color: #e9eef0;
  border-color: #e1e7ea; }
  .alert-secondary hr {
    border-top-color: #d2dbe0; }
  .alert-secondary .alert-link {
    color: #353e42; }

.alert-success {
  color: #2d6552;
  background-color: #ddf3eb;
  border-color: #d0eee4; }
  .alert-success hr {
    border-top-color: #bde7d9; }
  .alert-success .alert-link {
    color: #1d4235; }

.alert-info {
  color: #2d4165;
  background-color: #dde5f3;
  border-color: #d0dbee; }
  .alert-info hr {
    border-top-color: #bdcde7; }
  .alert-info .alert-link {
    color: #1d2a42; }

.alert-warning {
  color: #65512d;
  background-color: #f3ebdd;
  border-color: #eee3d0; }
  .alert-warning hr {
    border-top-color: #e7d8bd; }
  .alert-warning .alert-link {
    color: #42351d; }

.alert-danger {
  color: #65362d;
  background-color: #f3e1dd;
  border-color: #eed4d0; }
  .alert-danger hr {
    border-top-color: #e7c3bd; }
  .alert-danger .alert-link {
    color: #42231d; }

.alert-light {
  color: #5c6366;
  background-color: #eff2f3;
  border-color: #e9edef; }
  .alert-light hr {
    border-top-color: #dae1e4; }
  .alert-light .alert-link {
    color: #44494b; }

.alert-dark {
  color: #1d2325;
  background-color: #d7dada;
  border-color: #c7cbcb; }
  .alert-dark hr {
    border-top-color: #babfbf; }
  .alert-dark .alert-link {
    color: #070808; }

.alert-primary-2 {
  color: #214e5e;
  background-color: #d9eaf0;
  border-color: #c9e2ea; }
  .alert-primary-2 hr {
    border-top-color: #b7d8e3; }
  .alert-primary-2 .alert-link {
    color: #142f38; }

.alert-dark-blue {
  color: #003d49;
  background-color: #cce3e8;
  border-color: #b8d8df; }
  .alert-dark-blue hr {
    border-top-color: #a6ced7; }
  .alert-dark-blue .alert-link {
    color: #001216; }

.alert-lighter {
  color: #6c7072;
  background-color: #f5f7f8;
  border-color: #f2f4f5; }
  .alert-lighter hr {
    border-top-color: #e4e8ea; }
  .alert-lighter .alert-link {
    color: #535658; }

.alert-lightest {
  color: #7c7e7e;
  background-color: #fcfcfd;
  border-color: #fbfbfc; }
  .alert-lightest hr {
    border-top-color: #ececf1; }
  .alert-lightest .alert-link {
    color: #636464; }

.alert-white {
  color: #858585;
  background-color: white;
  border-color: white; }
  .alert-white hr {
    border-top-color: #f2f2f2; }
  .alert-white .alert-link {
    color: #6c6c6c; }

.alert-faint {
  color: #808181;
  background-color: #fdfefe;
  border-color: #fdfdfd; }
  .alert-faint hr {
    border-top-color: #f0f0f0; }
  .alert-faint .alert-link {
    color: #676767; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #FFF;
  text-align: center;
  white-space: nowrap;
  background-color: #f8991d;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #FFF;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:last-child {
    margin-bottom: 0; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #FFF; }
  .list-group-item.active {
    z-index: 2;
    color: #FFF;
    background-color: #f8991d;
    border-color: #f8991d; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #81500f;
  background-color: #fde2c0; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #81500f;
    background-color: #fcd7a7; }
  .list-group-item-primary.list-group-item-action.active {
    color: #FFF;
    background-color: #81500f;
    border-color: #81500f; }

.list-group-item-secondary {
  color: #4c585e;
  background-color: #e1e7ea; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #4c585e;
    background-color: #d2dbe0; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #FFF;
    background-color: #4c585e;
    border-color: #4c585e; }

.list-group-item-success {
  color: #2d6552;
  background-color: #d0eee4; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #2d6552;
    background-color: #bde7d9; }
  .list-group-item-success.list-group-item-action.active {
    color: #FFF;
    background-color: #2d6552;
    border-color: #2d6552; }

.list-group-item-info {
  color: #2d4165;
  background-color: #d0dbee; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #2d4165;
    background-color: #bdcde7; }
  .list-group-item-info.list-group-item-action.active {
    color: #FFF;
    background-color: #2d4165;
    border-color: #2d4165; }

.list-group-item-warning {
  color: #65512d;
  background-color: #eee3d0; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #65512d;
    background-color: #e7d8bd; }
  .list-group-item-warning.list-group-item-action.active {
    color: #FFF;
    background-color: #65512d;
    border-color: #65512d; }

.list-group-item-danger {
  color: #65362d;
  background-color: #eed4d0; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #65362d;
    background-color: #e7c3bd; }
  .list-group-item-danger.list-group-item-action.active {
    color: #FFF;
    background-color: #65362d;
    border-color: #65362d; }

.list-group-item-light {
  color: #5c6366;
  background-color: #e9edef; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #5c6366;
    background-color: #dae1e4; }
  .list-group-item-light.list-group-item-action.active {
    color: #FFF;
    background-color: #5c6366;
    border-color: #5c6366; }

.list-group-item-dark {
  color: #1d2325;
  background-color: #c7cbcb; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1d2325;
    background-color: #babfbf; }
  .list-group-item-dark.list-group-item-action.active {
    color: #FFF;
    background-color: #1d2325;
    border-color: #1d2325; }

.list-group-item-primary-2 {
  color: #214e5e;
  background-color: #c9e2ea; }
  .list-group-item-primary-2.list-group-item-action:hover, .list-group-item-primary-2.list-group-item-action:focus {
    color: #214e5e;
    background-color: #b7d8e3; }
  .list-group-item-primary-2.list-group-item-action.active {
    color: #FFF;
    background-color: #214e5e;
    border-color: #214e5e; }

.list-group-item-dark-blue {
  color: #003d49;
  background-color: #b8d8df; }
  .list-group-item-dark-blue.list-group-item-action:hover, .list-group-item-dark-blue.list-group-item-action:focus {
    color: #003d49;
    background-color: #a6ced7; }
  .list-group-item-dark-blue.list-group-item-action.active {
    color: #FFF;
    background-color: #003d49;
    border-color: #003d49; }

.list-group-item-lighter {
  color: #6c7072;
  background-color: #f2f4f5; }
  .list-group-item-lighter.list-group-item-action:hover, .list-group-item-lighter.list-group-item-action:focus {
    color: #6c7072;
    background-color: #e4e8ea; }
  .list-group-item-lighter.list-group-item-action.active {
    color: #FFF;
    background-color: #6c7072;
    border-color: #6c7072; }

.list-group-item-lightest {
  color: #7c7e7e;
  background-color: #fbfbfc; }
  .list-group-item-lightest.list-group-item-action:hover, .list-group-item-lightest.list-group-item-action:focus {
    color: #7c7e7e;
    background-color: #ececf1; }
  .list-group-item-lightest.list-group-item-action.active {
    color: #FFF;
    background-color: #7c7e7e;
    border-color: #7c7e7e; }

.list-group-item-white {
  color: #858585;
  background-color: white; }
  .list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  .list-group-item-white.list-group-item-action.active {
    color: #FFF;
    background-color: #858585;
    border-color: #858585; }

.list-group-item-faint {
  color: #808181;
  background-color: #fdfdfd; }
  .list-group-item-faint.list-group-item-action:hover, .list-group-item-faint.list-group-item-action:focus {
    color: #808181;
    background-color: #f0f0f0; }
  .list-group-item-faint.list-group-item-action.active {
    color: #FFF;
    background-color: #808181;
    border-color: #808181; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #FFF;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #FFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Montserrat", "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #FFF;
  text-align: center;
  background-color: #000; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Montserrat", "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #FFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2); }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.375rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #FFF; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.375rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #FFF; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #FFF; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.375rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #FFF; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb; }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #FFF;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #FFF;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #FFF;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #FFF;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #f8991d !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #db7f07 !important; }

.bg-secondary {
  background-color: #93a9b4 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #75919f !important; }

.bg-success {
  background-color: #57c29d !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #3da984 !important; }

.bg-info {
  background-color: #577dc2 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #3d63a9 !important; }

.bg-warning {
  background-color: #c29c57 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #a9833d !important; }

.bg-danger {
  background-color: #c26757 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #a94d3d !important; }

.bg-light {
  background-color: #b0bec5 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #93a6af !important; }

.bg-dark {
  background-color: #374447 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #21282a !important; }

.bg-primary-2 {
  background-color: #3f96b4 !important; }

a.bg-primary-2:hover, a.bg-primary-2:focus,
button.bg-primary-2:hover,
button.bg-primary-2:focus {
  background-color: #32778e !important; }

.bg-dark-blue {
  background-color: #00758D !important; }

a.bg-dark-blue:hover, a.bg-dark-blue:focus,
button.bg-dark-blue:hover,
button.bg-dark-blue:focus {
  background-color: #004b5a !important; }

.bg-lighter {
  background-color: #cfd8dc !important; }

a.bg-lighter:hover, a.bg-lighter:focus,
button.bg-lighter:hover,
button.bg-lighter:focus {
  background-color: #b2c0c6 !important; }

.bg-lightest {
  background-color: #eff2f3 !important; }

a.bg-lightest:hover, a.bg-lightest:focus,
button.bg-lightest:hover,
button.bg-lightest:focus {
  background-color: #d2dadd !important; }

.bg-white {
  background-color: #FFF !important; }

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important; }

.bg-faint {
  background-color: #f7f9f9 !important; }

a.bg-faint:hover, a.bg-faint:focus,
button.bg-faint:hover,
button.bg-faint:focus {
  background-color: #dae3e3 !important; }

.bg-white {
  background-color: #FFF !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #f8991d !important; }

.border-secondary {
  border-color: #93a9b4 !important; }

.border-success {
  border-color: #57c29d !important; }

.border-info {
  border-color: #577dc2 !important; }

.border-warning {
  border-color: #c29c57 !important; }

.border-danger {
  border-color: #c26757 !important; }

.border-light {
  border-color: #b0bec5 !important; }

.border-dark {
  border-color: #374447 !important; }

.border-primary-2 {
  border-color: #3f96b4 !important; }

.border-dark-blue {
  border-color: #00758D !important; }

.border-lighter {
  border-color: #cfd8dc !important; }

.border-lightest {
  border-color: #eff2f3 !important; }

.border-white {
  border-color: #FFF !important; }

.border-faint {
  border-color: #f7f9f9 !important; }

.border-white {
  border-color: #FFF !important; }

.rounded-sm {
  border-radius: 0.1875rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.375rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.25rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.25rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.25rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.25rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.25rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 0.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 0.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 0.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 0.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 0.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.m-6 {
  margin: 0.75rem !important; }

.mt-6,
.my-6 {
  margin-top: 0.75rem !important; }

.mr-6,
.mx-6 {
  margin-right: 0.75rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 0.75rem !important; }

.ml-6,
.mx-6 {
  margin-left: 0.75rem !important; }

.m-8 {
  margin: 1rem !important; }

.mt-8,
.my-8 {
  margin-top: 1rem !important; }

.mr-8,
.mx-8 {
  margin-right: 1rem !important; }

.mb-8,
.my-8 {
  margin-bottom: 1rem !important; }

.ml-8,
.mx-8 {
  margin-left: 1rem !important; }

.m-10 {
  margin: 1.25rem !important; }

.mt-10,
.my-10 {
  margin-top: 1.25rem !important; }

.mr-10,
.mx-10 {
  margin-right: 1.25rem !important; }

.mb-10,
.my-10 {
  margin-bottom: 1.25rem !important; }

.ml-10,
.mx-10 {
  margin-left: 1.25rem !important; }

.m-12 {
  margin: 1.5rem !important; }

.mt-12,
.my-12 {
  margin-top: 1.5rem !important; }

.mr-12,
.mx-12 {
  margin-right: 1.5rem !important; }

.mb-12,
.my-12 {
  margin-bottom: 1.5rem !important; }

.ml-12,
.mx-12 {
  margin-left: 1.5rem !important; }

.m-16 {
  margin: 2rem !important; }

.mt-16,
.my-16 {
  margin-top: 2rem !important; }

.mr-16,
.mx-16 {
  margin-right: 2rem !important; }

.mb-16,
.my-16 {
  margin-bottom: 2rem !important; }

.ml-16,
.mx-16 {
  margin-left: 2rem !important; }

.m-20 {
  margin: 2.5rem !important; }

.mt-20,
.my-20 {
  margin-top: 2.5rem !important; }

.mr-20,
.mx-20 {
  margin-right: 2.5rem !important; }

.mb-20,
.my-20 {
  margin-bottom: 2.5rem !important; }

.ml-20,
.mx-20 {
  margin-left: 2.5rem !important; }

.m-24 {
  margin: 3rem !important; }

.mt-24,
.my-24 {
  margin-top: 3rem !important; }

.mr-24,
.mx-24 {
  margin-right: 3rem !important; }

.mb-24,
.my-24 {
  margin-bottom: 3rem !important; }

.ml-24,
.mx-24 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.25rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.25rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.25rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.25rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.25rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 0.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 0.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 0.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 0.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 0.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.p-6 {
  padding: 0.75rem !important; }

.pt-6,
.py-6 {
  padding-top: 0.75rem !important; }

.pr-6,
.px-6 {
  padding-right: 0.75rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 0.75rem !important; }

.pl-6,
.px-6 {
  padding-left: 0.75rem !important; }

.p-8 {
  padding: 1rem !important; }

.pt-8,
.py-8 {
  padding-top: 1rem !important; }

.pr-8,
.px-8 {
  padding-right: 1rem !important; }

.pb-8,
.py-8 {
  padding-bottom: 1rem !important; }

.pl-8,
.px-8 {
  padding-left: 1rem !important; }

.p-10 {
  padding: 1.25rem !important; }

.pt-10,
.py-10 {
  padding-top: 1.25rem !important; }

.pr-10,
.px-10 {
  padding-right: 1.25rem !important; }

.pb-10,
.py-10 {
  padding-bottom: 1.25rem !important; }

.pl-10,
.px-10 {
  padding-left: 1.25rem !important; }

.p-12 {
  padding: 1.5rem !important; }

.pt-12,
.py-12 {
  padding-top: 1.5rem !important; }

.pr-12,
.px-12 {
  padding-right: 1.5rem !important; }

.pb-12,
.py-12 {
  padding-bottom: 1.5rem !important; }

.pl-12,
.px-12 {
  padding-left: 1.5rem !important; }

.p-16 {
  padding: 2rem !important; }

.pt-16,
.py-16 {
  padding-top: 2rem !important; }

.pr-16,
.px-16 {
  padding-right: 2rem !important; }

.pb-16,
.py-16 {
  padding-bottom: 2rem !important; }

.pl-16,
.px-16 {
  padding-left: 2rem !important; }

.p-20 {
  padding: 2.5rem !important; }

.pt-20,
.py-20 {
  padding-top: 2.5rem !important; }

.pr-20,
.px-20 {
  padding-right: 2.5rem !important; }

.pb-20,
.py-20 {
  padding-bottom: 2.5rem !important; }

.pl-20,
.px-20 {
  padding-left: 2.5rem !important; }

.p-24 {
  padding: 3rem !important; }

.pt-24,
.py-24 {
  padding-top: 3rem !important; }

.pr-24,
.px-24 {
  padding-right: 3rem !important; }

.pb-24,
.py-24 {
  padding-bottom: 3rem !important; }

.pl-24,
.px-24 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.25rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.25rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.25rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.25rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.25rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -0.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -0.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -0.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -0.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -0.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-n6 {
  margin: -0.75rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -0.75rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -0.75rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -0.75rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -0.75rem !important; }

.m-n8 {
  margin: -1rem !important; }

.mt-n8,
.my-n8 {
  margin-top: -1rem !important; }

.mr-n8,
.mx-n8 {
  margin-right: -1rem !important; }

.mb-n8,
.my-n8 {
  margin-bottom: -1rem !important; }

.ml-n8,
.mx-n8 {
  margin-left: -1rem !important; }

.m-n10 {
  margin: -1.25rem !important; }

.mt-n10,
.my-n10 {
  margin-top: -1.25rem !important; }

.mr-n10,
.mx-n10 {
  margin-right: -1.25rem !important; }

.mb-n10,
.my-n10 {
  margin-bottom: -1.25rem !important; }

.ml-n10,
.mx-n10 {
  margin-left: -1.25rem !important; }

.m-n12 {
  margin: -1.5rem !important; }

.mt-n12,
.my-n12 {
  margin-top: -1.5rem !important; }

.mr-n12,
.mx-n12 {
  margin-right: -1.5rem !important; }

.mb-n12,
.my-n12 {
  margin-bottom: -1.5rem !important; }

.ml-n12,
.mx-n12 {
  margin-left: -1.5rem !important; }

.m-n16 {
  margin: -2rem !important; }

.mt-n16,
.my-n16 {
  margin-top: -2rem !important; }

.mr-n16,
.mx-n16 {
  margin-right: -2rem !important; }

.mb-n16,
.my-n16 {
  margin-bottom: -2rem !important; }

.ml-n16,
.mx-n16 {
  margin-left: -2rem !important; }

.m-n20 {
  margin: -2.5rem !important; }

.mt-n20,
.my-n20 {
  margin-top: -2.5rem !important; }

.mr-n20,
.mx-n20 {
  margin-right: -2.5rem !important; }

.mb-n20,
.my-n20 {
  margin-bottom: -2.5rem !important; }

.ml-n20,
.mx-n20 {
  margin-left: -2.5rem !important; }

.m-n24 {
  margin: -3rem !important; }

.mt-n24,
.my-n24 {
  margin-top: -3rem !important; }

.mr-n24,
.mx-n24 {
  margin-right: -3rem !important; }

.mb-n24,
.my-n24 {
  margin-bottom: -3rem !important; }

.ml-n24,
.mx-n24 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.25rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.25rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.25rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.25rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 0.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 0.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 0.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 0.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .m-sm-6 {
    margin: 0.75rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 0.75rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 0.75rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 0.75rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 0.75rem !important; }
  .m-sm-8 {
    margin: 1rem !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 1rem !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 1rem !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 1rem !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 1rem !important; }
  .m-sm-10 {
    margin: 1.25rem !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 1.25rem !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 1.25rem !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 1.25rem !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 1.25rem !important; }
  .m-sm-12 {
    margin: 1.5rem !important; }
  .mt-sm-12,
  .my-sm-12 {
    margin-top: 1.5rem !important; }
  .mr-sm-12,
  .mx-sm-12 {
    margin-right: 1.5rem !important; }
  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-12,
  .mx-sm-12 {
    margin-left: 1.5rem !important; }
  .m-sm-16 {
    margin: 2rem !important; }
  .mt-sm-16,
  .my-sm-16 {
    margin-top: 2rem !important; }
  .mr-sm-16,
  .mx-sm-16 {
    margin-right: 2rem !important; }
  .mb-sm-16,
  .my-sm-16 {
    margin-bottom: 2rem !important; }
  .ml-sm-16,
  .mx-sm-16 {
    margin-left: 2rem !important; }
  .m-sm-20 {
    margin: 2.5rem !important; }
  .mt-sm-20,
  .my-sm-20 {
    margin-top: 2.5rem !important; }
  .mr-sm-20,
  .mx-sm-20 {
    margin-right: 2.5rem !important; }
  .mb-sm-20,
  .my-sm-20 {
    margin-bottom: 2.5rem !important; }
  .ml-sm-20,
  .mx-sm-20 {
    margin-left: 2.5rem !important; }
  .m-sm-24 {
    margin: 3rem !important; }
  .mt-sm-24,
  .my-sm-24 {
    margin-top: 3rem !important; }
  .mr-sm-24,
  .mx-sm-24 {
    margin-right: 3rem !important; }
  .mb-sm-24,
  .my-sm-24 {
    margin-bottom: 3rem !important; }
  .ml-sm-24,
  .mx-sm-24 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.25rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.25rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.25rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.25rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 0.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 0.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 0.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 0.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .p-sm-6 {
    padding: 0.75rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 0.75rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 0.75rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 0.75rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 0.75rem !important; }
  .p-sm-8 {
    padding: 1rem !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 1rem !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 1rem !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 1rem !important; }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 1rem !important; }
  .p-sm-10 {
    padding: 1.25rem !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 1.25rem !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 1.25rem !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 1.25rem !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 1.25rem !important; }
  .p-sm-12 {
    padding: 1.5rem !important; }
  .pt-sm-12,
  .py-sm-12 {
    padding-top: 1.5rem !important; }
  .pr-sm-12,
  .px-sm-12 {
    padding-right: 1.5rem !important; }
  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-12,
  .px-sm-12 {
    padding-left: 1.5rem !important; }
  .p-sm-16 {
    padding: 2rem !important; }
  .pt-sm-16,
  .py-sm-16 {
    padding-top: 2rem !important; }
  .pr-sm-16,
  .px-sm-16 {
    padding-right: 2rem !important; }
  .pb-sm-16,
  .py-sm-16 {
    padding-bottom: 2rem !important; }
  .pl-sm-16,
  .px-sm-16 {
    padding-left: 2rem !important; }
  .p-sm-20 {
    padding: 2.5rem !important; }
  .pt-sm-20,
  .py-sm-20 {
    padding-top: 2.5rem !important; }
  .pr-sm-20,
  .px-sm-20 {
    padding-right: 2.5rem !important; }
  .pb-sm-20,
  .py-sm-20 {
    padding-bottom: 2.5rem !important; }
  .pl-sm-20,
  .px-sm-20 {
    padding-left: 2.5rem !important; }
  .p-sm-24 {
    padding: 3rem !important; }
  .pt-sm-24,
  .py-sm-24 {
    padding-top: 3rem !important; }
  .pr-sm-24,
  .px-sm-24 {
    padding-right: 3rem !important; }
  .pb-sm-24,
  .py-sm-24 {
    padding-bottom: 3rem !important; }
  .pl-sm-24,
  .px-sm-24 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.25rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.25rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.25rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.25rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -0.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -0.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -0.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -0.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-n6 {
    margin: -0.75rem !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -0.75rem !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -0.75rem !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -0.75rem !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -0.75rem !important; }
  .m-sm-n8 {
    margin: -1rem !important; }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -1rem !important; }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -1rem !important; }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -1rem !important; }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -1rem !important; }
  .m-sm-n10 {
    margin: -1.25rem !important; }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -1.25rem !important; }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -1.25rem !important; }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -1.25rem !important; }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -1.25rem !important; }
  .m-sm-n12 {
    margin: -1.5rem !important; }
  .mt-sm-n12,
  .my-sm-n12 {
    margin-top: -1.5rem !important; }
  .mr-sm-n12,
  .mx-sm-n12 {
    margin-right: -1.5rem !important; }
  .mb-sm-n12,
  .my-sm-n12 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n12,
  .mx-sm-n12 {
    margin-left: -1.5rem !important; }
  .m-sm-n16 {
    margin: -2rem !important; }
  .mt-sm-n16,
  .my-sm-n16 {
    margin-top: -2rem !important; }
  .mr-sm-n16,
  .mx-sm-n16 {
    margin-right: -2rem !important; }
  .mb-sm-n16,
  .my-sm-n16 {
    margin-bottom: -2rem !important; }
  .ml-sm-n16,
  .mx-sm-n16 {
    margin-left: -2rem !important; }
  .m-sm-n20 {
    margin: -2.5rem !important; }
  .mt-sm-n20,
  .my-sm-n20 {
    margin-top: -2.5rem !important; }
  .mr-sm-n20,
  .mx-sm-n20 {
    margin-right: -2.5rem !important; }
  .mb-sm-n20,
  .my-sm-n20 {
    margin-bottom: -2.5rem !important; }
  .ml-sm-n20,
  .mx-sm-n20 {
    margin-left: -2.5rem !important; }
  .m-sm-n24 {
    margin: -3rem !important; }
  .mt-sm-n24,
  .my-sm-n24 {
    margin-top: -3rem !important; }
  .mr-sm-n24,
  .mx-sm-n24 {
    margin-right: -3rem !important; }
  .mb-sm-n24,
  .my-sm-n24 {
    margin-bottom: -3rem !important; }
  .ml-sm-n24,
  .mx-sm-n24 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.25rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.25rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.25rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.25rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.25rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 0.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 0.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 0.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 0.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 0.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .m-md-6 {
    margin: 0.75rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 0.75rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 0.75rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 0.75rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 0.75rem !important; }
  .m-md-8 {
    margin: 1rem !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 1rem !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 1rem !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 1rem !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 1rem !important; }
  .m-md-10 {
    margin: 1.25rem !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 1.25rem !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 1.25rem !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 1.25rem !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 1.25rem !important; }
  .m-md-12 {
    margin: 1.5rem !important; }
  .mt-md-12,
  .my-md-12 {
    margin-top: 1.5rem !important; }
  .mr-md-12,
  .mx-md-12 {
    margin-right: 1.5rem !important; }
  .mb-md-12,
  .my-md-12 {
    margin-bottom: 1.5rem !important; }
  .ml-md-12,
  .mx-md-12 {
    margin-left: 1.5rem !important; }
  .m-md-16 {
    margin: 2rem !important; }
  .mt-md-16,
  .my-md-16 {
    margin-top: 2rem !important; }
  .mr-md-16,
  .mx-md-16 {
    margin-right: 2rem !important; }
  .mb-md-16,
  .my-md-16 {
    margin-bottom: 2rem !important; }
  .ml-md-16,
  .mx-md-16 {
    margin-left: 2rem !important; }
  .m-md-20 {
    margin: 2.5rem !important; }
  .mt-md-20,
  .my-md-20 {
    margin-top: 2.5rem !important; }
  .mr-md-20,
  .mx-md-20 {
    margin-right: 2.5rem !important; }
  .mb-md-20,
  .my-md-20 {
    margin-bottom: 2.5rem !important; }
  .ml-md-20,
  .mx-md-20 {
    margin-left: 2.5rem !important; }
  .m-md-24 {
    margin: 3rem !important; }
  .mt-md-24,
  .my-md-24 {
    margin-top: 3rem !important; }
  .mr-md-24,
  .mx-md-24 {
    margin-right: 3rem !important; }
  .mb-md-24,
  .my-md-24 {
    margin-bottom: 3rem !important; }
  .ml-md-24,
  .mx-md-24 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.25rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.25rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.25rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.25rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.25rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 0.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 0.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 0.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 0.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 0.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .p-md-6 {
    padding: 0.75rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 0.75rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 0.75rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 0.75rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 0.75rem !important; }
  .p-md-8 {
    padding: 1rem !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 1rem !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 1rem !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 1rem !important; }
  .pl-md-8,
  .px-md-8 {
    padding-left: 1rem !important; }
  .p-md-10 {
    padding: 1.25rem !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 1.25rem !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 1.25rem !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 1.25rem !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 1.25rem !important; }
  .p-md-12 {
    padding: 1.5rem !important; }
  .pt-md-12,
  .py-md-12 {
    padding-top: 1.5rem !important; }
  .pr-md-12,
  .px-md-12 {
    padding-right: 1.5rem !important; }
  .pb-md-12,
  .py-md-12 {
    padding-bottom: 1.5rem !important; }
  .pl-md-12,
  .px-md-12 {
    padding-left: 1.5rem !important; }
  .p-md-16 {
    padding: 2rem !important; }
  .pt-md-16,
  .py-md-16 {
    padding-top: 2rem !important; }
  .pr-md-16,
  .px-md-16 {
    padding-right: 2rem !important; }
  .pb-md-16,
  .py-md-16 {
    padding-bottom: 2rem !important; }
  .pl-md-16,
  .px-md-16 {
    padding-left: 2rem !important; }
  .p-md-20 {
    padding: 2.5rem !important; }
  .pt-md-20,
  .py-md-20 {
    padding-top: 2.5rem !important; }
  .pr-md-20,
  .px-md-20 {
    padding-right: 2.5rem !important; }
  .pb-md-20,
  .py-md-20 {
    padding-bottom: 2.5rem !important; }
  .pl-md-20,
  .px-md-20 {
    padding-left: 2.5rem !important; }
  .p-md-24 {
    padding: 3rem !important; }
  .pt-md-24,
  .py-md-24 {
    padding-top: 3rem !important; }
  .pr-md-24,
  .px-md-24 {
    padding-right: 3rem !important; }
  .pb-md-24,
  .py-md-24 {
    padding-bottom: 3rem !important; }
  .pl-md-24,
  .px-md-24 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.25rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.25rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.25rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.25rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -0.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -0.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -0.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -0.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-n6 {
    margin: -0.75rem !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -0.75rem !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -0.75rem !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -0.75rem !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -0.75rem !important; }
  .m-md-n8 {
    margin: -1rem !important; }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -1rem !important; }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -1rem !important; }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -1rem !important; }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -1rem !important; }
  .m-md-n10 {
    margin: -1.25rem !important; }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -1.25rem !important; }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -1.25rem !important; }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -1.25rem !important; }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -1.25rem !important; }
  .m-md-n12 {
    margin: -1.5rem !important; }
  .mt-md-n12,
  .my-md-n12 {
    margin-top: -1.5rem !important; }
  .mr-md-n12,
  .mx-md-n12 {
    margin-right: -1.5rem !important; }
  .mb-md-n12,
  .my-md-n12 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n12,
  .mx-md-n12 {
    margin-left: -1.5rem !important; }
  .m-md-n16 {
    margin: -2rem !important; }
  .mt-md-n16,
  .my-md-n16 {
    margin-top: -2rem !important; }
  .mr-md-n16,
  .mx-md-n16 {
    margin-right: -2rem !important; }
  .mb-md-n16,
  .my-md-n16 {
    margin-bottom: -2rem !important; }
  .ml-md-n16,
  .mx-md-n16 {
    margin-left: -2rem !important; }
  .m-md-n20 {
    margin: -2.5rem !important; }
  .mt-md-n20,
  .my-md-n20 {
    margin-top: -2.5rem !important; }
  .mr-md-n20,
  .mx-md-n20 {
    margin-right: -2.5rem !important; }
  .mb-md-n20,
  .my-md-n20 {
    margin-bottom: -2.5rem !important; }
  .ml-md-n20,
  .mx-md-n20 {
    margin-left: -2.5rem !important; }
  .m-md-n24 {
    margin: -3rem !important; }
  .mt-md-n24,
  .my-md-n24 {
    margin-top: -3rem !important; }
  .mr-md-n24,
  .mx-md-n24 {
    margin-right: -3rem !important; }
  .mb-md-n24,
  .my-md-n24 {
    margin-bottom: -3rem !important; }
  .ml-md-n24,
  .mx-md-n24 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.25rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.25rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.25rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.25rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 0.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 0.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 0.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 0.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .m-lg-6 {
    margin: 0.75rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 0.75rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 0.75rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 0.75rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 0.75rem !important; }
  .m-lg-8 {
    margin: 1rem !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 1rem !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 1rem !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 1rem !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 1rem !important; }
  .m-lg-10 {
    margin: 1.25rem !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 1.25rem !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 1.25rem !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 1.25rem !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 1.25rem !important; }
  .m-lg-12 {
    margin: 1.5rem !important; }
  .mt-lg-12,
  .my-lg-12 {
    margin-top: 1.5rem !important; }
  .mr-lg-12,
  .mx-lg-12 {
    margin-right: 1.5rem !important; }
  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-12,
  .mx-lg-12 {
    margin-left: 1.5rem !important; }
  .m-lg-16 {
    margin: 2rem !important; }
  .mt-lg-16,
  .my-lg-16 {
    margin-top: 2rem !important; }
  .mr-lg-16,
  .mx-lg-16 {
    margin-right: 2rem !important; }
  .mb-lg-16,
  .my-lg-16 {
    margin-bottom: 2rem !important; }
  .ml-lg-16,
  .mx-lg-16 {
    margin-left: 2rem !important; }
  .m-lg-20 {
    margin: 2.5rem !important; }
  .mt-lg-20,
  .my-lg-20 {
    margin-top: 2.5rem !important; }
  .mr-lg-20,
  .mx-lg-20 {
    margin-right: 2.5rem !important; }
  .mb-lg-20,
  .my-lg-20 {
    margin-bottom: 2.5rem !important; }
  .ml-lg-20,
  .mx-lg-20 {
    margin-left: 2.5rem !important; }
  .m-lg-24 {
    margin: 3rem !important; }
  .mt-lg-24,
  .my-lg-24 {
    margin-top: 3rem !important; }
  .mr-lg-24,
  .mx-lg-24 {
    margin-right: 3rem !important; }
  .mb-lg-24,
  .my-lg-24 {
    margin-bottom: 3rem !important; }
  .ml-lg-24,
  .mx-lg-24 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.25rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.25rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.25rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.25rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 0.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 0.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 0.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 0.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .p-lg-6 {
    padding: 0.75rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 0.75rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 0.75rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 0.75rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 0.75rem !important; }
  .p-lg-8 {
    padding: 1rem !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 1rem !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 1rem !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 1rem !important; }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 1rem !important; }
  .p-lg-10 {
    padding: 1.25rem !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 1.25rem !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 1.25rem !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 1.25rem !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 1.25rem !important; }
  .p-lg-12 {
    padding: 1.5rem !important; }
  .pt-lg-12,
  .py-lg-12 {
    padding-top: 1.5rem !important; }
  .pr-lg-12,
  .px-lg-12 {
    padding-right: 1.5rem !important; }
  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-12,
  .px-lg-12 {
    padding-left: 1.5rem !important; }
  .p-lg-16 {
    padding: 2rem !important; }
  .pt-lg-16,
  .py-lg-16 {
    padding-top: 2rem !important; }
  .pr-lg-16,
  .px-lg-16 {
    padding-right: 2rem !important; }
  .pb-lg-16,
  .py-lg-16 {
    padding-bottom: 2rem !important; }
  .pl-lg-16,
  .px-lg-16 {
    padding-left: 2rem !important; }
  .p-lg-20 {
    padding: 2.5rem !important; }
  .pt-lg-20,
  .py-lg-20 {
    padding-top: 2.5rem !important; }
  .pr-lg-20,
  .px-lg-20 {
    padding-right: 2.5rem !important; }
  .pb-lg-20,
  .py-lg-20 {
    padding-bottom: 2.5rem !important; }
  .pl-lg-20,
  .px-lg-20 {
    padding-left: 2.5rem !important; }
  .p-lg-24 {
    padding: 3rem !important; }
  .pt-lg-24,
  .py-lg-24 {
    padding-top: 3rem !important; }
  .pr-lg-24,
  .px-lg-24 {
    padding-right: 3rem !important; }
  .pb-lg-24,
  .py-lg-24 {
    padding-bottom: 3rem !important; }
  .pl-lg-24,
  .px-lg-24 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.25rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.25rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.25rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.25rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -0.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -0.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -0.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -0.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-n6 {
    margin: -0.75rem !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -0.75rem !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -0.75rem !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -0.75rem !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -0.75rem !important; }
  .m-lg-n8 {
    margin: -1rem !important; }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -1rem !important; }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -1rem !important; }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -1rem !important; }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -1rem !important; }
  .m-lg-n10 {
    margin: -1.25rem !important; }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -1.25rem !important; }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -1.25rem !important; }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -1.25rem !important; }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -1.25rem !important; }
  .m-lg-n12 {
    margin: -1.5rem !important; }
  .mt-lg-n12,
  .my-lg-n12 {
    margin-top: -1.5rem !important; }
  .mr-lg-n12,
  .mx-lg-n12 {
    margin-right: -1.5rem !important; }
  .mb-lg-n12,
  .my-lg-n12 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n12,
  .mx-lg-n12 {
    margin-left: -1.5rem !important; }
  .m-lg-n16 {
    margin: -2rem !important; }
  .mt-lg-n16,
  .my-lg-n16 {
    margin-top: -2rem !important; }
  .mr-lg-n16,
  .mx-lg-n16 {
    margin-right: -2rem !important; }
  .mb-lg-n16,
  .my-lg-n16 {
    margin-bottom: -2rem !important; }
  .ml-lg-n16,
  .mx-lg-n16 {
    margin-left: -2rem !important; }
  .m-lg-n20 {
    margin: -2.5rem !important; }
  .mt-lg-n20,
  .my-lg-n20 {
    margin-top: -2.5rem !important; }
  .mr-lg-n20,
  .mx-lg-n20 {
    margin-right: -2.5rem !important; }
  .mb-lg-n20,
  .my-lg-n20 {
    margin-bottom: -2.5rem !important; }
  .ml-lg-n20,
  .mx-lg-n20 {
    margin-left: -2.5rem !important; }
  .m-lg-n24 {
    margin: -3rem !important; }
  .mt-lg-n24,
  .my-lg-n24 {
    margin-top: -3rem !important; }
  .mr-lg-n24,
  .mx-lg-n24 {
    margin-right: -3rem !important; }
  .mb-lg-n24,
  .my-lg-n24 {
    margin-bottom: -3rem !important; }
  .ml-lg-n24,
  .mx-lg-n24 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.25rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.25rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.25rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.25rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 0.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 0.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 0.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 0.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .m-xl-6 {
    margin: 0.75rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 0.75rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 0.75rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 0.75rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 0.75rem !important; }
  .m-xl-8 {
    margin: 1rem !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 1rem !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 1rem !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 1rem !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 1rem !important; }
  .m-xl-10 {
    margin: 1.25rem !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 1.25rem !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 1.25rem !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 1.25rem !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 1.25rem !important; }
  .m-xl-12 {
    margin: 1.5rem !important; }
  .mt-xl-12,
  .my-xl-12 {
    margin-top: 1.5rem !important; }
  .mr-xl-12,
  .mx-xl-12 {
    margin-right: 1.5rem !important; }
  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-12,
  .mx-xl-12 {
    margin-left: 1.5rem !important; }
  .m-xl-16 {
    margin: 2rem !important; }
  .mt-xl-16,
  .my-xl-16 {
    margin-top: 2rem !important; }
  .mr-xl-16,
  .mx-xl-16 {
    margin-right: 2rem !important; }
  .mb-xl-16,
  .my-xl-16 {
    margin-bottom: 2rem !important; }
  .ml-xl-16,
  .mx-xl-16 {
    margin-left: 2rem !important; }
  .m-xl-20 {
    margin: 2.5rem !important; }
  .mt-xl-20,
  .my-xl-20 {
    margin-top: 2.5rem !important; }
  .mr-xl-20,
  .mx-xl-20 {
    margin-right: 2.5rem !important; }
  .mb-xl-20,
  .my-xl-20 {
    margin-bottom: 2.5rem !important; }
  .ml-xl-20,
  .mx-xl-20 {
    margin-left: 2.5rem !important; }
  .m-xl-24 {
    margin: 3rem !important; }
  .mt-xl-24,
  .my-xl-24 {
    margin-top: 3rem !important; }
  .mr-xl-24,
  .mx-xl-24 {
    margin-right: 3rem !important; }
  .mb-xl-24,
  .my-xl-24 {
    margin-bottom: 3rem !important; }
  .ml-xl-24,
  .mx-xl-24 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.25rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.25rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.25rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.25rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 0.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 0.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 0.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 0.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .p-xl-6 {
    padding: 0.75rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 0.75rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 0.75rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 0.75rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 0.75rem !important; }
  .p-xl-8 {
    padding: 1rem !important; }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 1rem !important; }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 1rem !important; }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 1rem !important; }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 1rem !important; }
  .p-xl-10 {
    padding: 1.25rem !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 1.25rem !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 1.25rem !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 1.25rem !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 1.25rem !important; }
  .p-xl-12 {
    padding: 1.5rem !important; }
  .pt-xl-12,
  .py-xl-12 {
    padding-top: 1.5rem !important; }
  .pr-xl-12,
  .px-xl-12 {
    padding-right: 1.5rem !important; }
  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-12,
  .px-xl-12 {
    padding-left: 1.5rem !important; }
  .p-xl-16 {
    padding: 2rem !important; }
  .pt-xl-16,
  .py-xl-16 {
    padding-top: 2rem !important; }
  .pr-xl-16,
  .px-xl-16 {
    padding-right: 2rem !important; }
  .pb-xl-16,
  .py-xl-16 {
    padding-bottom: 2rem !important; }
  .pl-xl-16,
  .px-xl-16 {
    padding-left: 2rem !important; }
  .p-xl-20 {
    padding: 2.5rem !important; }
  .pt-xl-20,
  .py-xl-20 {
    padding-top: 2.5rem !important; }
  .pr-xl-20,
  .px-xl-20 {
    padding-right: 2.5rem !important; }
  .pb-xl-20,
  .py-xl-20 {
    padding-bottom: 2.5rem !important; }
  .pl-xl-20,
  .px-xl-20 {
    padding-left: 2.5rem !important; }
  .p-xl-24 {
    padding: 3rem !important; }
  .pt-xl-24,
  .py-xl-24 {
    padding-top: 3rem !important; }
  .pr-xl-24,
  .px-xl-24 {
    padding-right: 3rem !important; }
  .pb-xl-24,
  .py-xl-24 {
    padding-bottom: 3rem !important; }
  .pl-xl-24,
  .px-xl-24 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.25rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.25rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.25rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.25rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -0.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -0.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -0.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -0.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-n6 {
    margin: -0.75rem !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -0.75rem !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -0.75rem !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -0.75rem !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -0.75rem !important; }
  .m-xl-n8 {
    margin: -1rem !important; }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -1rem !important; }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -1rem !important; }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -1rem !important; }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -1rem !important; }
  .m-xl-n10 {
    margin: -1.25rem !important; }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -1.25rem !important; }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -1.25rem !important; }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -1.25rem !important; }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -1.25rem !important; }
  .m-xl-n12 {
    margin: -1.5rem !important; }
  .mt-xl-n12,
  .my-xl-n12 {
    margin-top: -1.5rem !important; }
  .mr-xl-n12,
  .mx-xl-n12 {
    margin-right: -1.5rem !important; }
  .mb-xl-n12,
  .my-xl-n12 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n12,
  .mx-xl-n12 {
    margin-left: -1.5rem !important; }
  .m-xl-n16 {
    margin: -2rem !important; }
  .mt-xl-n16,
  .my-xl-n16 {
    margin-top: -2rem !important; }
  .mr-xl-n16,
  .mx-xl-n16 {
    margin-right: -2rem !important; }
  .mb-xl-n16,
  .my-xl-n16 {
    margin-bottom: -2rem !important; }
  .ml-xl-n16,
  .mx-xl-n16 {
    margin-left: -2rem !important; }
  .m-xl-n20 {
    margin: -2.5rem !important; }
  .mt-xl-n20,
  .my-xl-n20 {
    margin-top: -2.5rem !important; }
  .mr-xl-n20,
  .mx-xl-n20 {
    margin-right: -2.5rem !important; }
  .mb-xl-n20,
  .my-xl-n20 {
    margin-bottom: -2.5rem !important; }
  .ml-xl-n20,
  .mx-xl-n20 {
    margin-left: -2.5rem !important; }
  .m-xl-n24 {
    margin: -3rem !important; }
  .mt-xl-n24,
  .my-xl-n24 {
    margin-top: -3rem !important; }
  .mr-xl-n24,
  .mx-xl-n24 {
    margin-right: -3rem !important; }
  .mb-xl-n24,
  .my-xl-n24 {
    margin-bottom: -3rem !important; }
  .ml-xl-n24,
  .mx-xl-n24 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #FFF !important; }

.text-primary {
  color: #f8991d !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #c27106 !important; }

.text-secondary {
  color: #93a9b4 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #678594 !important; }

.text-success {
  color: #57c29d !important; }

a.text-success:hover, a.text-success:focus {
  color: #369675 !important; }

.text-info {
  color: #577dc2 !important; }

a.text-info:hover, a.text-info:focus {
  color: #365896 !important; }

.text-warning {
  color: #c29c57 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #967436 !important; }

.text-danger {
  color: #c26757 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #964536 !important; }

.text-light {
  color: #b0bec5 !important; }

a.text-light:hover, a.text-light:focus {
  color: #849aa5 !important; }

.text-dark {
  color: #374447 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #161b1c !important; }

.text-primary-2 {
  color: #3f96b4 !important; }

a.text-primary-2:hover, a.text-primary-2:focus {
  color: #2b677b !important; }

.text-dark-blue {
  color: #00758D !important; }

a.text-dark-blue:hover, a.text-dark-blue:focus {
  color: #003641 !important; }

.text-lighter {
  color: #cfd8dc !important; }

a.text-lighter:hover, a.text-lighter:focus {
  color: #a3b4bc !important; }

.text-lightest {
  color: #eff2f3 !important; }

a.text-lightest:hover, a.text-lightest:focus {
  color: #c3ced2 !important; }

.text-white {
  color: #FFF !important; }

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important; }

.text-faint {
  color: #f7f9f9 !important; }

a.text-faint:hover, a.text-faint:focus {
  color: #cbd8d8 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #FFF !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

.border-dashed {
  border-style: dashed !important; }

hr.section-divider {
  border-width: 3px;
  border-color: #f8991d;
  width: 4rem;
  margin: 2rem auto; }

.no-shadow {
  box-shadow: none !important; }

.btn-link-primary {
  color: #f8991d; }
  .btn-link-primary:hover {
    color: #aa6205; }
  .btn-link-primary.disabled, .btn-link-primary:disabled {
    color: #f8991d;
    background-color: transparent; }

.btn-link-secondary {
  color: #93a9b4; }
  .btn-link-secondary:hover {
    color: #5c7785; }
  .btn-link-secondary.disabled, .btn-link-secondary:disabled {
    color: #93a9b4;
    background-color: transparent; }

.btn-link-success {
  color: #57c29d; }
  .btn-link-success:hover {
    color: #308366; }
  .btn-link-success.disabled, .btn-link-success:disabled {
    color: #57c29d;
    background-color: transparent; }

.btn-link-info {
  color: #577dc2; }
  .btn-link-info:hover {
    color: #304d83; }
  .btn-link-info.disabled, .btn-link-info:disabled {
    color: #577dc2;
    background-color: transparent; }

.btn-link-warning {
  color: #c29c57; }
  .btn-link-warning:hover {
    color: #836630; }
  .btn-link-warning.disabled, .btn-link-warning:disabled {
    color: #c29c57;
    background-color: transparent; }

.btn-link-danger {
  color: #c26757; }
  .btn-link-danger:hover {
    color: #833c30; }
  .btn-link-danger.disabled, .btn-link-danger:disabled {
    color: #c26757;
    background-color: transparent; }

.btn-link-light {
  color: #b0bec5; }
  .btn-link-light:hover {
    color: #758e9a; }
  .btn-link-light.disabled, .btn-link-light:disabled {
    color: #b0bec5;
    background-color: transparent; }

.btn-link-dark {
  color: #374447; }
  .btn-link-dark:hover {
    color: #0a0d0e; }
  .btn-link-dark.disabled, .btn-link-dark:disabled {
    color: #374447;
    background-color: transparent; }

.btn-link-primary-2 {
  color: #3f96b4; }
  .btn-link-primary-2:hover {
    color: #255768; }
  .btn-link-primary-2.disabled, .btn-link-primary-2:disabled {
    color: #3f96b4;
    background-color: transparent; }

.btn-link-dark-blue {
  color: #00758D; }
  .btn-link-dark-blue:hover {
    color: #002027; }
  .btn-link-dark-blue.disabled, .btn-link-dark-blue:disabled {
    color: #00758D;
    background-color: transparent; }

.btn-link-lighter {
  color: #cfd8dc; }
  .btn-link-lighter:hover {
    color: #94a8b1; }
  .btn-link-lighter.disabled, .btn-link-lighter:disabled {
    color: #cfd8dc;
    background-color: transparent; }

.btn-link-lightest {
  color: #eff2f3; }
  .btn-link-lightest:hover {
    color: #b5c3c7; }
  .btn-link-lightest.disabled, .btn-link-lightest:disabled {
    color: #eff2f3;
    background-color: transparent; }

.btn-link-white {
  color: #FFF; }
  .btn-link-white:hover {
    color: #cccccc; }
  .btn-link-white.disabled, .btn-link-white:disabled {
    color: #FFF;
    background-color: transparent; }

.btn-link-faint {
  color: #f7f9f9; }
  .btn-link-faint:hover {
    color: #bdcdcd; }
  .btn-link-faint.disabled, .btn-link-faint:disabled {
    color: #f7f9f9;
    background-color: transparent; }

.btn.btn-icon, .survey-scale .custom-control.custom-radio .btn-icon.custom-control-label, .markdown-body blockquote a.btn-icon {
  position: relative; }
  .btn.btn-icon svg, .survey-scale .custom-control.custom-radio .btn-icon.custom-control-label svg, .markdown-body blockquote a.btn-icon svg {
    background-color: rgba(0, 0, 0, 0.2);
    width: 55px;
    height: 100%;
    top: 0;
    padding: 12px; }
  .btn.btn-icon.btn-icon-right, .survey-scale .custom-control.custom-radio .btn-icon.btn-icon-right.custom-control-label, .markdown-body blockquote a.btn-icon.btn-icon-right {
    padding-right: 52px; }
    .btn.btn-icon.btn-icon-right svg, .survey-scale .custom-control.custom-radio .btn-icon.btn-icon-right.custom-control-label svg, .markdown-body blockquote a.btn-icon.btn-icon-right svg,
    .btn.btn-icon.btn-icon-right i,
    .survey-scale .custom-control.custom-radio .btn-icon.btn-icon-right.custom-control-label i,
    .markdown-body blockquote a.btn-icon.btn-icon-right i {
      right: 0; }
  .btn.btn-icon.btn-icon-left, .survey-scale .custom-control.custom-radio .btn-icon.btn-icon-left.custom-control-label, .markdown-body blockquote a.btn-icon.btn-icon-left {
    padding-left: 52px; }
    .btn.btn-icon.btn-icon-left svg, .survey-scale .custom-control.custom-radio .btn-icon.btn-icon-left.custom-control-label svg, .markdown-body blockquote a.btn-icon.btn-icon-left svg,
    .btn.btn-icon.btn-icon-left i,
    .survey-scale .custom-control.custom-radio .btn-icon.btn-icon-left.custom-control-label i,
    .markdown-body blockquote a.btn-icon.btn-icon-left i {
      left: 0; }
  .btn.btn-icon.btn-lg.btn-icon-right, .btn-group-lg > .btn.btn-icon.btn-icon-right, .survey-scale .custom-control.custom-radio .btn-group-lg > .btn-icon.btn-icon-right.custom-control-label, .markdown-body blockquote .btn-group-lg > a.btn-icon.btn-icon-right, .survey-scale .custom-control.custom-radio .btn-icon.btn-lg.btn-icon-right.custom-control-label, .markdown-body blockquote a.btn-icon.btn-lg.btn-icon-right {
    padding-right: 70px; }
  .btn.btn-icon.btn-lg.btn-icon-left, .btn-group-lg > .btn.btn-icon.btn-icon-left, .survey-scale .custom-control.custom-radio .btn-group-lg > .btn-icon.btn-icon-left.custom-control-label, .markdown-body blockquote .btn-group-lg > a.btn-icon.btn-icon-left, .survey-scale .custom-control.custom-radio .btn-icon.btn-lg.btn-icon-left.custom-control-label, .markdown-body blockquote a.btn-icon.btn-lg.btn-icon-left {
    padding-left: 70px; }
  .btn.btn-icon.btn-lg i, .btn-group-lg > .btn.btn-icon i, .survey-scale .custom-control.custom-radio .btn-group-lg > .btn-icon.custom-control-label i, .markdown-body blockquote .btn-group-lg > a.btn-icon i, .survey-scale .custom-control.custom-radio .btn-icon.btn-lg.custom-control-label i, .markdown-body blockquote a.btn-icon.btn-lg i,
  .btn.btn-icon.btn-lg svg,
  .btn-group-lg > .btn.btn-icon svg,
  .survey-scale .custom-control.custom-radio .btn-group-lg > .btn-icon.custom-control-label svg,
  .markdown-body blockquote .btn-group-lg > a.btn-icon svg,
  .survey-scale .custom-control.custom-radio .btn-icon.btn-lg.custom-control-label svg,
  .markdown-body blockquote a.btn-icon.btn-lg svg {
    padding: 13px 22px; }
  .btn.btn-icon i, .survey-scale .custom-control.custom-radio .btn-icon.custom-control-label i, .markdown-body blockquote a.btn-icon i {
    background-color: rgba(0, 0, 0, 0.2);
    padding: 10px 13px;
    position: absolute;
    top: 0;
    height: 100%;
    font-style: initial; }
  .btn.btn-icon.btn-outline-light:hover, .survey-scale .custom-control.custom-radio .btn-icon.btn-outline-light.custom-control-label:hover, .markdown-body blockquote a.btn-icon.btn-outline-light:hover {
    background-color: #f7f9f9;
    border-color: #f8991d;
    color: #b0bec5; }
  .btn.btn-icon.btn-outline-light i, .survey-scale .custom-control.custom-radio .btn-icon.btn-outline-light.custom-control-label i, .markdown-body blockquote a.btn-icon.btn-outline-light i {
    background-color: #FFF;
    border-left: 1px solid #b0bec5;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    color: #b0bec5;
    border-radius: 0;
    padding: 13px; }
    .btn.btn-icon.btn-outline-light i:active, .survey-scale .custom-control.custom-radio .btn-icon.btn-outline-light.custom-control-label i:active, .markdown-body blockquote a.btn-icon.btn-outline-light i:active {
      background-color: #FFF;
      border-left: 1px solid #f8991d;
      border-radius: 0; }
  .btn.btn-icon.btn-outline-light:hover i, .survey-scale .custom-control.custom-radio .btn-icon.btn-outline-light.custom-control-label:hover i, .markdown-body blockquote a.btn-icon.btn-outline-light:hover i {
    background-color: #f7f9f9;
    border-color: #f8991d; }
  .btn.btn-icon.btn-outline-light:focus i, .survey-scale .custom-control.custom-radio .btn-icon.btn-outline-light.custom-control-label:focus i, .markdown-body blockquote a.btn-icon.btn-outline-light:focus i {
    background-color: #FFF;
    border-left: 1px solid #f8991d; }

.btn:active, .survey-scale .custom-control.custom-radio .custom-control-label:active, .markdown-body blockquote a:active {
  opacity: .85; }

.submit-button {
  padding-top: 3rem;
  padding-bottom: 3rem; }

.close:focus {
  outline: none; }

.btn:not(:disabled):not(.disabled):active:focus, .survey-scale .custom-control.custom-radio .custom-control-label:not(:disabled):not(.disabled):active:focus, .markdown-body blockquote a:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled):focus, .survey-scale .custom-control.custom-radio .custom-control-label:not(:disabled):not(.disabled):focus, .markdown-body blockquote a:not(:disabled):not(.disabled):focus {
  box-shadow: none; }

@media (max-width: 767.98px) {
  .btn-sm-block {
    display: block;
    width: 100%; } }

.dropdown-language {
  margin-bottom: 0.5rem; }

.language-select {
  left: initial;
  right: 0;
  font-size: 0.875rem; }

.dropdown-item, .frauto-list li {
  white-space: initial; }

.aura-footer {
  background-color: #eff2f3;
  padding: 1rem;
  border-top: 1px solid #cfd8dc; }
  .aura-footer p {
    font-size: 0.75rem;
    color: #374447; }
    .aura-footer p a {
      color: #374447 !important;
      text-decoration: none;
      font-size: 0.75rem; }
      .aura-footer p a:hover {
        text-decoration: underline; }
  .aura-footer .footer-social-icons .social-icon {
    color: #374447; }
    .aura-footer .footer-social-icons .social-icon:hover {
      text-decoration: none; }

.form-help {
  margin-bottom: 1rem; }

.form-group {
  margin-bottom: 1rem;
  position: relative; }
  .form-group ::-webkit-input-placeholder {
    font-size: 1rem;
    color: #cfd8dc; }
  .form-group > label {
    width: 100%;
    margin-bottom: 0; }
  .form-group .label-text {
    font-size: 0.875rem;
    color: #374447;
    margin-bottom: 0.25rem;
    display: inline-block;
    font-weight: 400; }
  .form-group .field-optional {
    font-style: italic;
    font-size: 80%; }
    .form-group .field-optional::before {
      content: "-";
      margin-right: 0.25rem; }
  .form-group .form-control, .form-group .custom-select, .form-group .select2-container--default .select2-selection--single, .select2-container--default .form-group .select2-selection--single {
    background-color: #FFF;
    border: 1px solid #b0bec5;
    outline: none;
    outline-style: none;
    box-shadow: none; }
    .form-group .form-control.invalid, .form-group .invalid.custom-select, .form-group .select2-container--default .invalid.select2-selection--single, .select2-container--default .form-group .invalid.select2-selection--single {
      border-color: #c26757 !important; }
      .form-group .form-control.invalid + .input-group-append button, .form-group .invalid.custom-select + .input-group-append button, .form-group .select2-container--default .invalid.select2-selection--single + .input-group-append button, .select2-container--default .form-group .invalid.select2-selection--single + .input-group-append button {
        border-color: #c26757 !important; }
      .form-group .form-control.invalid + label.invalid + .input-group-append button, .form-group .invalid.custom-select + label.invalid + .input-group-append button, .form-group .select2-container--default .invalid.select2-selection--single + label.invalid + .input-group-append button, .select2-container--default .form-group .invalid.select2-selection--single + label.invalid + .input-group-append button {
        border-color: #c26757 !important; }
      .form-group .form-control.invalid + label + .input-group-append button, .form-group .invalid.custom-select + label + .input-group-append button, .form-group .select2-container--default .invalid.select2-selection--single + label + .input-group-append button, .select2-container--default .form-group .invalid.select2-selection--single + label + .input-group-append button {
        border-color: #c26757; }
      .form-group .form-control.invalid + label + .input-group-append button, .form-group .invalid.custom-select + label + .input-group-append button, .form-group .select2-container--default .invalid.select2-selection--single + label + .input-group-append button, .select2-container--default .form-group .invalid.select2-selection--single + label + .input-group-append button {
        border-color: #c26757; }
    .form-group .form-control + .registria_field + label.invalid, .form-group .custom-select + .registria_field + label.invalid, .form-group .select2-container--default .select2-selection--single + .registria_field + label.invalid, .select2-container--default .form-group .select2-selection--single + .registria_field + label.invalid {
      margin-top: .5rem;
      font-size: .75rem;
      color: #455a64;
      font-style: italic; }
    .form-group .form-control + label.invalid, .form-group .custom-select + label.invalid, .form-group .select2-container--default .select2-selection--single + label.invalid, .select2-container--default .form-group .select2-selection--single + label.invalid {
      color: #c26757 !important;
      position: absolute;
      right: 0;
      font-size: 0.65rem;
      text-align: right; }
    .form-group .form-control.invalid:focus + .input-group-append button, .form-group .invalid.custom-select:focus + .input-group-append button, .form-group .select2-container--default .invalid.select2-selection--single:focus + .input-group-append button, .select2-container--default .form-group .invalid.select2-selection--single:focus + .input-group-append button {
      border-color: #f8991d; }
    .form-group .form-control:focus, .form-group .custom-select:focus, .form-group .select2-container--default .select2-selection--single:focus, .select2-container--default .form-group .select2-selection--single:focus {
      border-color: #f8991d;
      outline: none;
      outline-style: none;
      box-shadow: none; }
    .form-group .form-control + .form-helper-text, .form-group .custom-select + .form-helper-text, .form-group .select2-container--default .select2-selection--single + .form-helper-text, .select2-container--default .form-group .select2-selection--single + .form-helper-text {
      margin-top: 0.5rem;
      font-size: 0.75rem;
      color: #374447;
      font-style: italic; }
      .form-group .form-control + .form-helper-text a, .form-group .custom-select + .form-helper-text a, .form-group .select2-container--default .select2-selection--single + .form-helper-text a, .select2-container--default .form-group .select2-selection--single + .form-helper-text a {
        font-size: 0.75rem;
        color: #577dc2; }
    .form-group .form-control + .input-group-append .input-group-text, .form-group .custom-select + .input-group-append .input-group-text, .form-group .select2-container--default .select2-selection--single + .input-group-append .input-group-text, .select2-container--default .form-group .select2-selection--single + .input-group-append .input-group-text {
      border: 1px solid #b0bec5;
      border-left: none;
      background-color: #FFF; }
    .form-group .form-control + .input-group-append button, .form-group .custom-select + .input-group-append button, .form-group .select2-container--default .select2-selection--single + .input-group-append button, .select2-container--default .form-group .select2-selection--single + .input-group-append button,
    .form-group .form-control + .input-group-append span,
    .form-group .custom-select + .input-group-append span,
    .form-group .select2-container--default .select2-selection--single + .input-group-append span,
    .select2-container--default .form-group .select2-selection--single + .input-group-append span {
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; }
  .form-group .input-group label.invalid {
    top: calc(1.5em + 0.75rem + 2px); }
  .form-group .input-group .input-group-append button {
    border-color: #b0bec5;
    color: #78909c; }
    .form-group .input-group .input-group-append button:hover {
      background-color: #eff2f3;
      color: #78909c; }
  .form-group ::-webkit-input-placeholder,
  .form-group ::-moz-placeholder,
  .form-group :-ms-input-placeholder,
  .form-group :-moz-placeholder {
    color: #b0bec5;
    font-size: 0.875rem; }
  .form-group .checkbox-opt-in-text {
    color: #374447;
    font-size: 0.875rem; }
  .form-group input[type=number]::-webkit-inner-spin-button,
  .form-group input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .form-group input[type=number] {
    -moz-appearance: textfield; }
  .form-group .custom-select {
    background: #f7f9f9;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23374447' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");
    /* fallback */
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, linear-gradient(to bottom, #f7f9f9 0%, #f7f9f9 75%);
    /* W3C */ }
    .form-group .custom-select + label.invalid {
      text-align: right; }
  .form-group .form-icon i {
    color: #f8991d; }

.select2-container {
  width: 100% !important; }
  .select2-container .select2-selection--single {
    height: calc(1.5em + 0.75rem + 2px) !important; }

.select2-container--default .select2-selection--single {
  border-radius: 0px !important;
  padding: initial; }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: calc(1.5em + 0.75rem + 2px) !important; }
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: calc(1.5em + 0.75rem + 2px) !important;
    top: 0px !important; }

.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .custom-file .custom-file-label {
  color: #b0bec5;
  font-size: 1rem;
  display: flex;
  align-items: center; }
  .input-group > .custom-file .custom-file-label:after {
    cursor: pointer; }
  .input-group > .custom-file .custom-file-label::after {
    display: none; }

.input-group > .custom-file .custom-file-input-icon {
  z-index: 1000;
  color: #b0bec5; }

.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  border-color: #ced4da;
  box-shadow: none; }

.input-group > .custom-file .custom-file-input.invalid + label.invalid {
  color: #c26757 !important;
  position: absolute;
  right: 0;
  top: 42px;
  font-size: 0.65rem; }
  .input-group > .custom-file .custom-file-input.invalid + label.invalid + label.upload-text {
    color: #c26757 !important;
    border-color: #c26757 !important; }
    .input-group > .custom-file .custom-file-input.invalid + label.invalid + label.upload-text + .custom-file-input-icon {
      color: #c26757 !important; }

.reg-2-page .reg-2-page-custom-fields .col-md-6 {
  flex: 0 0 100%;
  max-width: 100%; }

.frauto-list {
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
  left: initial;
  padding-top: 0;
  padding-bottom: 0;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); }
  .frauto-list li {
    line-height: 1rem !important;
    min-height: 2.75rem;
    font-size: 0.875rem !important;
    padding: 1rem !important;
    border-top: 1px solid #cfd8dc !important; }
    .frauto-list li strong {
      font-size: 0.875rem !important; }
    .frauto-list li:hover {
      cursor: pointer; }
    .frauto-list li:first-child {
      border-top: none !important; }
    .frauto-list li.selected {
      background-color: transparent !important; }

.custom-control {
  display: flex;
  align-items: center; }
  .custom-control .custom-control-label {
    cursor: pointer;
    font-size: 0.875rem;
    width: 100%;
    padding-left: 0.5rem; }
    .custom-control .custom-control-label:before, .custom-control .custom-control-label:after {
      height: 1.25rem;
      width: 1.25rem;
      top: 1px; }
    .custom-control .custom-control-label.item::before, .custom-control .custom-control-label.item::after {
      top: initial; }

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none; }

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #f8991d; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.15rem; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #f8991d; }

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #FFF;
  background-color: #f8991d;
  border-color: #f8991d; }

.custom-checkbox .custom-control-input.invalid + label.invalid {
  margin-bottom: 0;
  bottom: -15px; }
  .custom-checkbox .custom-control-input.invalid + label.invalid + .registria_field + label.custom-control-label {
    box-shadow: 0 0 0 1px #c26757 !important; }
    .custom-checkbox .custom-control-input.invalid + label.invalid + .registria_field + label.custom-control-label::before {
      border-color: #c26757 !important; }

.registria.form_errors p {
  color: #c26757; }

.survey-scale > label {
  display: block; }

.survey-scale .radio-group {
  display: flex;
  flex-direction: row; }

.survey-scale .custom-control {
  padding-left: 0;
  flex: 1 0 auto;
  margin-left: 0.25rem;
  margin-right: 0.25rem; }
  .survey-scale .custom-control:first-child {
    margin-left: 0; }
  .survey-scale .custom-control:last-child {
    margin-right: 0; }
  .survey-scale .custom-control.custom-radio .custom-control-label {
    display: inline;
    padding: 0.75rem;
    cursor: pointer; }
    .survey-scale .custom-control.custom-radio .custom-control-label:after, .survey-scale .custom-control.custom-radio .custom-control-label:before {
      display: none; }
    .survey-scale .custom-control.custom-radio .custom-control-label:active {
      opacity: 0.75; }
    .survey-scale .custom-control.custom-radio .custom-control-label .label-text {
      margin-bottom: 0; }
  .survey-scale .custom-control .custom-control-input:checked ~ .custom-control-label {
    background-color: #f8991d;
    color: #FFF;
    transition: background-color ease-in-out 0.25s; }

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* remove default arrow */ }

#map {
  height: 1px;
  width: 1px; }

#infowindow-content {
  display: none; }

#infowindow-content img {
  width: 1rem;
  height: 1rem; }

#map #infowindow-content {
  display: inline; }

.pac-logo::after {
  display: none; }

#full_address_label {
  margin-left: 0.5rem;
  color: #C7001B;
  font-size: 0.875rem; }

@media (max-width: 767.98px) {
  .survey-scale .radio-group {
    flex-direction: column-reverse;
    align-items: center; }
  .survey-scale .custom-control {
    margin: 0;
    width: 250px;
    max-width: 250px; } }

body, html {
  color: #374447;
  background-color: #FFF;
  height: 100%; }

body {
  max-width: 1600px;
  margin: 0 auto; }

.page-wrap {
  min-height: 100%;
  margin-bottom: -50px; }
  .page-wrap:after {
    content: "";
    display: block;
    height: 50px; }

.aura-header-mast {
  min-height: 325px;
  background-size: cover;
  background-position: center;
  margin-bottom: 0; }
  .aura-header-mast .header-mast-overlay {
    min-height: 325px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8991d; }

.app-body {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow-x: hidden;
  min-height: 500px; }

.aura-main {
  display: block;
  width: 100%; }

.aura-content {
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #FFFFFF;
  margin-top: 0px; }

.aura-container {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
  padding-left: 0;
  padding-right: 0;
  margin-top: -325px;
  margin-bottom: 2rem; }
  @media (min-width: 576px) {
    .aura-container {
      max-width: 767.98px; } }
  @media (min-width: 768px) {
    .aura-container {
      max-width: 991.96px; } }
  @media (min-width: 992px) {
    .aura-container {
      max-width: 991.97px; } }
  @media (min-width: 1200px) {
    .aura-container {
      max-width: 991.98px; } }

.aura-container-fluid {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto; }

.aura-content-container {
  justify-content: center; }
  .aura-content-container .content {
    flex: 0 0 60%;
    max-width: 60%;
    padding-left: 1rem;
    padding-right: 1rem; }
    .aura-content-container .content.content-sm {
      flex: 0 0 50%;
      max-width: 50%; }
    .aura-content-container .content.content-lg {
      flex: 0 0 100%;
      max-width: 1200px; }

section {
  margin-bottom: 1.5rem; }

.aura-page-headers {
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FFF;
  min-height: 325px; }
  .aura-page-headers.transparent {
    background-color: transparent; }
  .aura-page-headers .page-header-logo {
    max-height: 84px;
    margin-bottom: 1rem; }
    .aura-page-headers .page-header-logo img {
      max-height: 84px; }
  .aura-page-headers .page-header {
    color: #374447;
    padding: 0 3rem;
    text-align: center;
    font-size: 2rem; }
  .aura-page-headers .page-subheader {
    color: #374447;
    font-weight: 400;
    text-align: center;
    font-size: 1rem;
    margin-bottom: 0;
    line-height: 1.5rem;
    padding: 0 3rem; }
  .aura-page-headers .page-desc {
    text-align: center;
    padding-top: 1rem;
    font-size: 0.875rem; }

@media screen and (min-width: 1600px) {
  .page-wrap,
  .page-wrap-login,
  .aura-footer {
    border-left: 1px solid #cfd8dc;
    border-right: 1px solid #cfd8dc; } }

@media (max-width: 991.98px) {
  .aura-container {
    margin-top: 0px;
    margin-bottom: 0px;
    box-shadow: none; }
  .aura-header-mast {
    min-height: 0; }
    .aura-header-mast .header-mast-overlay {
      min-height: 0; }
  .aura-content-container .content {
    flex: 0 0 80%;
    max-width: 80%; }
    .aura-content-container .content.content-sm {
      flex: 0 0 100%;
      max-width: 100%; } }

@media (max-width: 767.98px) {
  .aura-page-headers {
    min-height: 0 !important;
    padding: 2rem 1rem; }
    .aura-page-headers .page-header {
      padding: 0; }
    .aura-page-headers .page-subheader {
      padding: 0; }
  .aura-content-container .content {
    flex: 0 0 100%;
    max-width: 100%; } }

.row, .aura-content-container {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
  margin-left: -1rem;
  margin-right: -1rem; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.aura-header {
  background-color: #FFF;
  height: 72px;
  z-index: 1021; }
  .aura-header .aura-header-inner {
    color: #374447;
    position: relative;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%; }
    .aura-header .aura-header-inner .nav-buttons-left {
      z-index: 1030; }
    .aura-header .aura-header-inner .navbar-toggler {
      height: 72px;
      border: none;
      background-color: #FFF;
      cursor: pointer;
      z-index: 1030;
      display: flex;
      align-items: center;
      text-decoration: none; }
      .aura-header .aura-header-inner .navbar-toggler i {
        margin-right: 0.25rem; }
      .aura-header .aura-header-inner .navbar-toggler:focus {
        outline: none; }
      .aura-header .aura-header-inner .navbar-toggler:hover {
        background-color: #eff2f3; }
    .aura-header .aura-header-inner .brand-logo {
      height: 72px;
      display: flex;
      align-items: center;
      position: absolute;
      left: 0;
      right: 0;
      padding-left: 1rem;
      padding-right: 1rem; }
      .aura-header .aura-header-inner .brand-logo img {
        max-height: 52px;
        max-width: 110px; }
    .aura-header .aura-header-inner .nav.dropdown-language {
      padding-right: 1rem;
      padding-left: 1rem;
      height: 72px;
      display: flex;
      align-items: center;
      z-index: 1030;
      margin-bottom: 0; }
      .aura-header .aura-header-inner .nav.dropdown-language .nav-dropdown {
        height: 72px;
        display: flex;
        align-items: center; }
      .aura-header .aura-header-inner .nav.dropdown-language:hover {
        box-shadow: inset 0px -3px 0px 0px #f8991d;
        transition: box-shadow 0.25s; }

@media (max-width: 991.98px) {
  .aura-header {
    border-bottom: 5px solid #f8991d; } }

@media (max-width: 767.98px) {
  .aura-header {
    box-shadow: none; }
    .aura-header .brand-logo {
      height: 52px;
      display: flex;
      align-items: center;
      position: relative;
      left: 0;
      right: 0; }
      .aura-header .brand-logo a.brand-logo-anchor {
        margin-right: 0; }
      .aura-header .brand-logo img {
        max-height: 52px;
        max-width: 200px; } }

.list {
  display: block;
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-bottom: 1.5rem; }
  .list.list-sm .item {
    padding: 0.75rem; }

.card .card-block-list .list {
  margin-bottom: 0; }
  .card .card-block-list .list .item {
    border-left: none;
    border-right: none; }
    .card .card-block-list .list .item:last-child {
      border-bottom: none; }
  .card .card-block-list .list.list-sm .item {
    min-height: 1.5rem; }

a.item {
  text-decoration: none;
  cursor: pointer; }

.item {
  text-align: initial;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0;
  padding: 1rem;
  width: 100%;
  min-height: 2.5rem;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  border: 1px solid #cfd8dc;
  margin-top: -1px; }
  .item.item-clickable {
    cursor: pointer; }
    .item.item-clickable:hover {
      background-color: #eff2f3 !important;
      text-decoration: none;
      color: initial;
      transition: background-color 0.25s ease; }
  .item.item-no-border {
    border: none;
    padding-bottom: 0.25rem;
    padding-top: 0.25rem; }
    .item.item-no-border:last-child {
      padding-bottom: 0.5rem; }
    .item.item-no-border .avatar {
      margin: 0.25rem 1rem 0.25rem 0; }
    .item.item-no-border.callout {
      border-left-width: 3px;
      border-left-style: solid; }
  .item.item-bottom-border {
    border-bottom: 1px solid #cfd8dc;
    border-top: none;
    border-left: none;
    border-right: none;
    padding-bottom: 0.75rem;
    padding-top: 0.75rem; }
    .item.item-bottom-border:last-child {
      border-bottom: none;
      padding-bottom: 0; }
    .item.item-bottom-border:first-child {
      padding-top: 0; }
  .item.item-divider {
    background-color: #FFF;
    font-size: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
    .item.item-divider .item-divider-title {
      font-family: 700;
      text-transform: uppercase;
      font-size: 1rem; }
  .item.item-primary {
    background-color: #f8991d;
    color: #FFF; }
    .item.item-primary .item-end i {
      color: #FFF; }
    .item.item-primary:hover {
      color: #FFF; }
  .item .avatar {
    margin: 0.5rem 1rem 0.5rem 0;
    display: flex;
    align-items: center;
    line-height: 1;
    background-color: #cfd8dc;
    border-radius: 50%;
    text-align: center;
    border: 1px solid #b0bec5; }
    .item .avatar.avatar-xs {
      width: 32px;
      height: 32px; }
      .item .avatar.avatar-xs svg {
        width: 32px;
        height: 32px;
        padding: 6px;
        font-size: 1.25rem;
        color: #7f7f7f; }
      .item .avatar.avatar-xs i {
        width: 32px;
        height: 32px;
        padding: 6px 0px;
        font-size: 1rem;
        color: #b0bec5; }
    .item .avatar.avatar-sm {
      width: 48px;
      height: 48px; }
      .item .avatar.avatar-sm svg {
        width: 46px;
        height: 46px;
        padding: 10px;
        font-size: 1.5rem;
        color: #b0bec5; }
      .item .avatar.avatar-sm i {
        width: 46px;
        height: 46px;
        padding: 11px 8px;
        font-size: 1.5rem;
        color: #b0bec5; }
    .item .avatar.avatar-lg {
      width: 64px;
      height: 64px; }
      .item .avatar.avatar-lg svg {
        width: 64px;
        height: 64px;
        padding: 12px;
        font-size: 1.5rem;
        color: #b0bec5; }
      .item .avatar.avatar-lg i {
        width: 64px;
        height: 64px;
        padding: 14px 11px;
        font-size: 2rem;
        color: #b0bec5; }
    .item .avatar.avatar-xl {
      width: 100px;
      height: 100px; }
      .item .avatar.avatar-xl svg {
        width: 65px;
        height: 100px;
        padding: 12px;
        font-size: 2rem;
        color: #b0bec5; }
      .item .avatar.avatar-xl i {
        width: 100px;
        height: 100px;
        padding: 14px;
        font-size: 2.5rem;
        color: #b0bec5; }
    .item .avatar.avatar-square {
      border-radius: 0px; }
      .item .avatar.avatar-square i, .item .avatar.avatar-square svg, .item .avatar.avatar-square img {
        border-radius: 0px; }
    .item .avatar img {
      border-radius: 50%;
      overflow: hidden;
      display: block;
      max-width: 100%;
      margin: 0 auto; }
  .item .item-inner {
    border-bottom: .55px solid #c8c7cc;
    padding-right: 0.5rem;
    display: flex;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-flex-direction: inherit;
    -ms-flex-direction: inherit;
    flex-direction: inherit;
    -webkit-align-items: inherit;
    -ms-flex-align: inherit;
    align-items: inherit;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    margin: 0;
    padding: 0;
    min-height: inherit;
    border: 0;
    overflow: hidden; }
    .item .item-inner .input-wrapper {
      display: flex;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      -webkit-flex-direction: inherit;
      -ms-flex-direction: inherit;
      flex-direction: inherit;
      -webkit-align-items: inherit;
      -ms-flex-align: inherit;
      align-items: inherit;
      -webkit-align-self: stretch;
      -ms-flex-item-align: stretch;
      align-self: stretch;
      text-overflow: ellipsis;
      overflow: hidden; }
      .item .item-inner .input-wrapper .item-label {
        display: block;
        overflow: hidden;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin: 0;
        font-size: inherit; }
        .item .item-inner .input-wrapper .item-label .item-title {
          font-size: 1rem;
          margin: 0 0 2px;
          font-weight: 400; }
        .item .item-inner .input-wrapper .item-label a.item-title {
          color: primary; }
        .item .item-inner .input-wrapper .item-label a.item-title:hover {
          color: #db7f07; }
        .item .item-inner .input-wrapper .item-label .item-subtitle {
          overflow: inherit;
          font-size: 0.875rem;
          line-height: normal;
          color: #8e9093;
          margin-bottom: 0; }
        .item .item-inner .input-wrapper .item-label .item-desc {
          overflow: inherit;
          margin: 5px 0 0 0;
          font-size: 0.875rem;
          line-height: normal;
          color: #8e9093; }
        .item .item-inner .input-wrapper .item-label dl {
          margin-bottom: 0; }
          .item .item-inner .input-wrapper .item-label dl dt, .item .item-inner .input-wrapper .item-label dl dd {
            margin-bottom: 0px;
            color: #374447;
            font-size: 0.875rem; }
    .item .item-inner .item-button {
      padding: 0 .6em;
      height: 25px;
      font-size: 0.875rem; }
      .item .item-inner .item-button .button-inner {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-flow: row nowrap;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 100%; }
  .item.item-sm {
    min-height: 1.5rem; }
  .item .item-end {
    margin: 0px 0.5rem 0px 0.25rem; }
    .item .item-end.item-end-caret {
      color: #7f7f7f;
      font-size: 30px; }
  .item.item-primary {
    background: #f8991d;
    color: #FFF; }
    .item.item-primary .avatar {
      border-color: #FFF; }
      .item.item-primary .avatar i {
        color: #f8991d; }
    .item.item-primary .item-end i {
      color: #FFF; }
    .item.item-primary.clickable:hover {
      background: #f48d08 !important;
      color: #FFF; }
  .item.item-secondary {
    background: #93a9b4;
    color: #FFF; }
    .item.item-secondary .avatar {
      border-color: #FFF; }
      .item.item-secondary .avatar i {
        color: #93a9b4; }
    .item.item-secondary .item-end i {
      color: #FFF; }
    .item.item-secondary.clickable:hover {
      background: #849daa !important;
      color: #FFF; }
  .item.item-success {
    background: #57c29d;
    color: #FFF; }
    .item.item-success .avatar {
      border-color: #FFF; }
      .item.item-success .avatar i {
        color: #57c29d; }
    .item.item-success .item-end i {
      color: #FFF; }
    .item.item-success.clickable:hover {
      background: #44bb92 !important;
      color: #FFF; }
  .item.item-info {
    background: #577dc2;
    color: #FFF; }
    .item.item-info .avatar {
      border-color: #FFF; }
      .item.item-info .avatar i {
        color: #577dc2; }
    .item.item-info .item-end i {
      color: #FFF; }
    .item.item-info.clickable:hover {
      background: #446fbb !important;
      color: #FFF; }
  .item.item-warning {
    background: #c29c57;
    color: #FFF; }
    .item.item-warning .avatar {
      border-color: #FFF; }
      .item.item-warning .avatar i {
        color: #c29c57; }
    .item.item-warning .item-end i {
      color: #FFF; }
    .item.item-warning.clickable:hover {
      background: #bb9144 !important;
      color: #FFF; }
  .item.item-danger {
    background: #c26757;
    color: #FFF; }
    .item.item-danger .avatar {
      border-color: #FFF; }
      .item.item-danger .avatar i {
        color: #c26757; }
    .item.item-danger .item-end i {
      color: #FFF; }
    .item.item-danger.clickable:hover {
      background: #bb5644 !important;
      color: #FFF; }
  .item.item-light {
    background: #b0bec5;
    color: #FFF; }
    .item.item-light .avatar {
      border-color: #FFF; }
      .item.item-light .avatar i {
        color: #b0bec5; }
    .item.item-light .item-end i {
      color: #FFF; }
    .item.item-light.clickable:hover {
      background: #a1b2ba !important;
      color: #FFF; }
  .item.item-dark {
    background: #374447;
    color: #FFF; }
    .item.item-dark .avatar {
      border-color: #FFF; }
      .item.item-dark .avatar i {
        color: #374447; }
    .item.item-dark .item-end i {
      color: #FFF; }
    .item.item-dark.clickable:hover {
      background: #2c3639 !important;
      color: #FFF; }
  .item.item-primary-2 {
    background: #3f96b4;
    color: #FFF; }
    .item.item-primary-2 .avatar {
      border-color: #FFF; }
      .item.item-primary-2 .avatar i {
        color: #3f96b4; }
    .item.item-primary-2 .item-end i {
      color: #FFF; }
    .item.item-primary-2.clickable:hover {
      background: #3886a1 !important;
      color: #FFF; }
  .item.item-dark-blue {
    background: #00758D;
    color: #FFF; }
    .item.item-dark-blue .avatar {
      border-color: #FFF; }
      .item.item-dark-blue .avatar i {
        color: #00758D; }
    .item.item-dark-blue .item-end i {
      color: #FFF; }
    .item.item-dark-blue.clickable:hover {
      background: #006074 !important;
      color: #FFF; }
  .item.item-lighter {
    background: #cfd8dc;
    color: #FFF; }
    .item.item-lighter .avatar {
      border-color: #FFF; }
      .item.item-lighter .avatar i {
        color: #cfd8dc; }
    .item.item-lighter .item-end i {
      color: #FFF; }
    .item.item-lighter.clickable:hover {
      background: #c0ccd1 !important;
      color: #FFF; }
  .item.item-lightest {
    background: #eff2f3;
    color: #FFF; }
    .item.item-lightest .avatar {
      border-color: #FFF; }
      .item.item-lightest .avatar i {
        color: #eff2f3; }
    .item.item-lightest .item-end i {
      color: #FFF; }
    .item.item-lightest.clickable:hover {
      background: #e0e6e8 !important;
      color: #FFF; }
  .item.item-white {
    background: #FFF;
    color: #FFF; }
    .item.item-white .avatar {
      border-color: #FFF; }
      .item.item-white .avatar i {
        color: #FFF; }
    .item.item-white .item-end i {
      color: #FFF; }
    .item.item-white.clickable:hover {
      background: #f2f2f2 !important;
      color: #FFF; }
  .item.item-faint {
    background: #f7f9f9;
    color: #FFF; }
    .item.item-faint .avatar {
      border-color: #FFF; }
      .item.item-faint .avatar i {
        color: #f7f9f9; }
    .item.item-faint .item-end i {
      color: #FFF; }
    .item.item-faint.clickable:hover {
      background: #e8eeee !important;
      color: #FFF; }

a.item.bg-lightest:hover, a.item.bg-lightest:focus {
  background-color: #e6ebec !important;
  text-decoration: none; }

.list .custom-control .item-bottom-border {
  border-bottom: 1px solid #cfd8dc; }

.list .custom-control:last-child {
  border-bottom: none; }

.list-unstyled li {
  line-height: 1.75rem; }

.markdown-body img {
  max-width: 100%;
  height: auto; }

.markdown-body > *:last-child {
  margin-bottom: 0; }

.markdown-body hr {
  border-width: 3px;
  border-color: #f8991d;
  width: 4rem;
  margin: 2rem auto; }

.card-logos img {
  width: 40px; }

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0; }
  .video-wrapper:not(:first-child) {
    margin-top: 1rem; }
  .video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-body {
  padding: 1rem; }

.modal-content, .modal-header, .modal-footer {
  border-color: #cfd8dc; }

.modal-title {
  font-weight: 400;
  color: #374447; }

.merchandise-offers {
  flex-flow: row nowrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  display: flex;
  justify-content: center; }
  .merchandise-offers .merchandise-offer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 1 33.33333%;
    flex: 0 1 33.33333%;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 0.5rem;
    margin-bottom: 0;
    margin-left: 0.5rem;
    border: 1px dashed #b0bec5;
    padding: 1rem;
    border-radius: 0; }
    .merchandise-offers .merchandise-offer:hover {
      text-decoration: none; }
    .merchandise-offers .merchandise-offer .merchandise-desc {
      text-align: center; }
    .merchandise-offers .merchandise-offer .merchandise-price {
      text-align: center;
      margin-bottom: 1rem; }
    .merchandise-offers .merchandise-offer .best-value {
      text-transform: uppercase;
      font-size: 0.875rem;
      font-weight: 700;
      color: #f8991d; }
    .merchandise-offers .merchandise-offer .merchandise-img {
      max-width: 180px;
      height: 200px;
      text-align: center;
      margin-bottom: 1rem; }
      .merchandise-offers .merchandise-offer .merchandise-img img {
        max-width: 180px;
        object-fit: contain;
        height: 100%; }
    .merchandise-offers .merchandise-offer .merchandise-text {
      width: 100%; }
    .merchandise-offers .merchandise-offer .merchandise-title {
      font-weight: 700;
      font-size: 0.875rem; }
    .merchandise-offers .merchandise-offer .merchandise-subtitle {
      line-height: 1.25rem;
      margin: 0; }
    .merchandise-offers .merchandise-offer .merchandise-button {
      margin-top: 1rem; }
    .merchandise-offers .merchandise-offer.active {
      border: 1px solid transparent;
      box-shadow: 0 0 0pt 2pt #f8991d; }
      .merchandise-offers .merchandise-offer.active .checkbox-add-to-cart-text {
        color: #f8991d; }

.esp .custom-control:first-child {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  background-color: #eff2f3;
  border: 1px solid #cfd8dc; }

.esp-benefits-item {
  display: flex;
  align-items: left;
  flex-direction: row; }
  .esp-benefits-item .esp-benefits-item-icon {
    font-size: 1.5rem;
    color: #f8991d;
    margin-right: 0.75rem;
    margin-bottom: 0.5rem; }
  .esp-benefits-item .esp-benefits-item-text {
    font-size: 0.875rem;
    max-width: 168px !important; }

@media (max-width: 767.98px) {
  .merchandise-offers {
    flex-flow: row wrap;
    margin-right: 0;
    margin-left: 0; }
    .merchandise-offers .merchandise-offer {
      flex: 1 1 100%;
      margin-bottom: 1rem;
      margin-right: 0;
      margin-left: 0; }
      .merchandise-offers .merchandise-offer:last-of-type {
        margin-bottom: 0; }
      .merchandise-offers .merchandise-offer .merchandise-img {
        width: 35%;
        min-height: 0;
        margin-bottom: 0;
        height: auto; }
        .merchandise-offers .merchandise-offer .merchandise-img img {
          max-width: 100%;
          height: auto; }
      .merchandise-offers .merchandise-offer .merchandise-text {
        width: 65%; } }

.table.table-sm.border-none th {
  border-bottom: none;
  border-top: none; }

.table.table-sm.border-none td {
  border-top: none;
  font-size: 0.875rem; }

h1, .h1 {
  font-size: 2rem; }

h2, .h2 {
  font-size: 1.75rem; }

h3, .h3 {
  font-size: 1.5rem; }

h4, .h4 {
  font-size: 1.25rem; }

h5, .h5 {
  font-size: 1.125rem; }

h6, .h6 {
  font-size: 1rem; }

p,
.p {
  font-size: 1rem; }

.font-size-sm {
  font-size: 0.875rem; }

.font-size-lg {
  font-size: 1.25rem; }

.font-size-xl {
  font-size: 1.25rem; }

.font-size-xxl {
  font-size: 1.25rem; }

.display-5 {
  font-size: 3rem; }

.display-6 {
  font-size: 2.5rem; }

.clickable {
  cursor: pointer; }

.card-body dl,
.card-body dt,
.card-body dd {
  font-size: 0.875rem;
  margin-bottom: 0.25rem; }

.description-list dl {
  font-size: 0.875rem; }

.description-list dd {
  font-size: 0.875rem; }

ol,
ul {
  list-style-position: inside;
  padding-left: 0; }

.section-header {
  color: #374447;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  font-weight: 700;
  border-bottom: 1px solid #cfd8dc;
  font-size: 1rem; }

.section-header-centered {
  text-align: center;
  color: #374447;
  font-weight: 400;
  font-size: 1.25rem; }
  .section-header-centered + p {
    text-align: center;
    color: #374447;
    font-weight: 300; }

/* ---------------- CONTAINER ----------------*/
.all-brands a.item {
  text-decoration: none; }
  .all-brands a.item .item-title {
    text-transform: uppercase; }

.b2c {
  /* ---------------- TYPOGRAPHY ----------------*/
  /* ---------------- MOBILE STYLING ----------------*/
  /* ---------------- TABLET STYLING ----------------*/
  /* ---------------- CUSTOM BREAKPOINTS ----------------*/ }
  .b2c .header-mast-overlay {
    background-color: transparent;
    min-height: 920px; }
  .b2c .aura-page-headers .page-header-title {
    text-transform: uppercase;
    font-size: 3rem;
    letter-spacing: 6px;
    color: #3f96b4;
    text-align: center; }
  .b2c .aura-header-mast {
    background-size: cover;
    background-position: center;
    color: #fff;
    align-items: initial;
    min-height: 920px; }
  .b2c .brand-logo-anchor {
    text-decoration: none; }
  .b2c .aura-container {
    max-width: none; }
  .b2c #b2c-01 .row, .b2c #b2c-01 .aura-content-container {
    min-height: 525px;
    background-size: cover;
    background-position: right;
    transform: scaleX(-1); }
  .b2c #b2c-01 h2 {
    transform: scaleX(-1);
    line-height: 68px !important; }
  .b2c #b2c-02 {
    margin-top: -5%; }
  .b2c #b2c-03 img.bullet {
    max-width: 20px; }
  .b2c #b2c-04 hr {
    max-width: 80px; }
  .b2c i {
    font-size: 36px;
    min-width: 41px;
    color: #3f96b4; }
    .b2c i:hover {
      color: #f8991d; }
  .b2c #b2c-05 .row, .b2c #b2c-05 .aura-content-container {
    min-height: 525px;
    background-position: 90%; }
  .b2c .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #008CBA; }
  .b2c .text {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    color: #FFF !important;
    font-size: 20px; }
  .b2c .obj-container:hover .overlay {
    opacity: 1; }
  .b2c #b2c-07 {
    display: none; }
  .b2c #b2c-07 img.how-to-01 {
    max-width: 300px; }
  .b2c #b2c-08 {
    display: none; }
  .b2c #b2c-08 img {
    max-height: 400px; }
  .b2c h1, .b2c h2, .b2c h3, .b2c h4, .b2c h5, .b2c h6, .b2c .h1, .b2c .h2, .b2c .h3, .b2c .h4, .b2c .h5, .b2c .h6 {
    line-height: 1.4 !important; }
  .b2c .aura-content h2, .b2c .h2 {
    color: #FFA400;
    line-height: 56px !important; }
  .b2c h4, .b2c .h4 {
    font-size: 1.25rem;
    line-height: 30px;
    letter-spacing: .55px; }
  .b2c p, .b2c .p {
    font-size: 1rem;
    line-height: 24px;
    letter-spacing: .4;
    color: #374447; }
  @media (max-width: 767.98px) {
    .b2c .header-mast-overlay {
      min-height: 400px; }
    .b2c .aura-header-mast {
      background-size: cover;
      min-height: 400px; }
    .b2c #b2c-01 .row, .b2c #b2c-01 .aura-content-container {
      background-size: cover;
      background-position: -80px;
      background-repeat: no-repeat;
      min-height: 240px; }
    .b2c #b2c-01 h2 {
      line-height: 28px !important; }
    .b2c #b2c-02 img {
      max-width: 40%; }
    .b2c #b2c-03 img.phones {
      max-width: 75%;
      margin-top: -15%; }
    .b2c #b2c-03 .features-text {
      background-color: #f7f9f9; }
    .b2c .features-text {
      margin-top: -10%; }
    .b2c #b2c-05 .row, .b2c #b2c-05 .aura-content-container {
      background-size: cover;
      background-position: -80px;
      background-repeat: no-repeat;
      min-height: 320px; }
    .b2c .aura-content h2, .b2c .h2 {
      font-size: 18px !important;
      line-height: 28px !important; }
    .b2c i {
      float: left; }
    .b2c #b2c-07 img.how-to-01 {
      max-width: 200px; }
    .b2c #b2c-08 img {
      max-height: 200px; }
    .b2c .hidden-xs {
      display: none; } }
  @media (max-width: 991.98px) {
    .b2c .aura-page-headers {
      min-height: 0;
      padding: 2rem 1rem; }
    .b2c .aura-page-headers .page-header-title {
      font-size: 2rem; }
    .b2c h3, .b2c .h3 {
      letter-spacing: .5px; }
    .b2c h4, .b2c .h4 {
      line-height: 24px;
      letter-spacing: .4px; } }
  @media (min-width: 576px) and (max-width: 991.98px) {
    .b2c #b2c-01 .row, .b2c #b2c-01 .aura-content-container {
      min-height: 300px; }
    .b2c .aura-content h2, .b2c .h2 {
      font-size: 28px !important;
      line-height: 42px !important; }
    .b2c #b2c-01 h2 {
      line-height: 42px !important; } }
  @media (min-width: 630px) and (max-width: 768px) {
    .b2c #b2c-03 img {
      margin-top: 0px; } }
  @media (min-width: 768px) and (max-width: 1150px) {
    .b2c #b2c-05 .row, .b2c #b2c-05 .aura-content-container {
      background-position: 60%; } }

/* ---------------- CONTAINER ----------------*/
.featured-brands .aura-page-headers {
  background-image: url(../images/header.jpg);
  background-size: cover;
  background-position: center;
  color: #fff;
  align-items: initial; }

.featured-brands .register-more-header {
  padding: 140px 0px 12px 0px;
  color: #374447 !important; }
  .featured-brands .register-more-header h1.headline {
    font-weight: 400;
    font-size: 6.4rem;
    letter-spacing: 6.7px;
    text-transform: uppercase;
    line-height: 1; }
    .featured-brands .register-more-header h1.headline span.line-2 {
      font-weight: 400;
      font-size: 38px;
      line-height: 38px !important;
      letter-spacing: 1.6px;
      text-transform: uppercase;
      display: block;
      margin-left: 8px; }
  .featured-brands .register-more-header img {
    width: 48px;
    position: absolute;
    top: 0;
    left: 580px; }
  .featured-brands .register-more-header img.triangle {
    max-width: 14px;
    height: 45px; }

.featured-brands .brand-link {
  text-decoration: none; }
  .featured-brands .brand-link:hover {
    text-decoration: none; }
  .featured-brands .brand-link .brand-link-inner {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color ease 0.25s;
    background-color: #FFF;
    color: #374447;
    font-weight: 700;
    border-top: 5px solid #cfd8dc;
    border-left: 1px solid #cfd8dc;
    border-right: 1px solid #cfd8dc;
    border-bottom: 1px solid #cfd8dc; }
    .featured-brands .brand-link .brand-link-inner:hover {
      background-color: #eff2f3; }
    .featured-brands .brand-link .brand-link-inner.all-clad {
      border-top: 5px solid #D21241; }
    .featured-brands .brand-link .brand-link-inner.amana {
      border-top: 5px solid #F42434; }
    .featured-brands .brand-link .brand-link-inner.atlantic {
      border-top: 5px solid #000000; }
    .featured-brands .brand-link .brand-link-inner.avent {
      border-top: 5px solid #631d76; }
    .featured-brands .brand-link .brand-link-inner.bosch {
      border-top: 5px solid #E00420; }
    .featured-brands .brand-link .brand-link-inner.bose {
      border-top: 5px solid #242424; }
    .featured-brands .brand-link .brand-link-inner.braun {
      border-top: 5px solid #72BF44; }
    .featured-brands .brand-link .brand-link-inner.breville {
      border-top: 5px solid #391538; }
    .featured-brands .brand-link .brand-link-inner.casablanca {
      border-top: 5px solid #3a7e8d; }
    .featured-brands .brand-link .brand-link-inner.chamberlain {
      border-top: 5px solid #0046AD; }
    .featured-brands .brand-link .brand-link-inner.coleman {
      border-top: 5px solid #B72C29; }
    .featured-brands .brand-link .brand-link-inner.conair {
      border-top: 5px solid #2696fa; }
    .featured-brands .brand-link .brand-link-inner.cuisinart {
      border-top: 5px solid #688D3B; }
    .featured-brands .brand-link .brand-link-inner.delonghi {
      border-top: 5px solid #0C2645; }
    .featured-brands .brand-link .brand-link-inner.dirt-devil {
      border-top: 5px solid #E31837; }
    .featured-brands .brand-link .brand-link-inner.dyson {
      border-top: 5px solid #000000; }
    .featured-brands .brand-link .brand-link-inner.electrolux {
      border-top: 5px solid #18204E; }
    .featured-brands .brand-link .brand-link-inner.freemotion {
      border-top: 5px solid #104777; }
    .featured-brands .brand-link .brand-link-inner.frigidaire {
      border-top: 5px solid #ee405a; }
    .featured-brands .brand-link .brand-link-inner.fujifilm {
      border-top: 5px solid #E6012D; }
    .featured-brands .brand-link .brand-link-inner.gaggenau {
      border-top: 5px solid #E37222; }
    .featured-brands .brand-link .brand-link-inner.ge {
      border-top: 5px solid #092c74; }
    .featured-brands .brand-link .brand-link-inner.hamilton-beach {
      border-top: 5px solid #77b802; }
    .featured-brands .brand-link .brand-link-inner.hayward {
      border-top: 5px solid #2196f3; }
    .featured-brands .brand-link .brand-link-inner.hisense {
      border-top: 5px solid #00CCCC; }
    .featured-brands .brand-link .brand-link-inner.hoover {
      border-top: 5px solid #BD282F; }
    .featured-brands .brand-link .brand-link-inner.hp {
      border-top: 5px solid #0096D6; }
    .featured-brands .brand-link .brand-link-inner.hunter {
      border-top: 5px solid #509e2f; }
    .featured-brands .brand-link .brand-link-inner.hunter-douglas {
      border-top: 5px solid #f08200; }
    .featured-brands .brand-link .brand-link-inner.insinkerator {
      border-top: 5px solid #236192; }
    .featured-brands .brand-link .brand-link-inner.instant-pot {
      border-top: 5px solid #d00; }
    .featured-brands .brand-link .brand-link-inner.instax {
      border-top: 5px solid #E6012D; }
    .featured-brands .brand-link .brand-link-inner.jennair {
      border-top: 5px solid #000000; }
    .featured-brands .brand-link .brand-link-inner.kitchenaid {
      border-top: 5px solid #C41230; }
    .featured-brands .brand-link .brand-link-inner.keurig {
      border-top: 5px solid #5b3427; }
    .featured-brands .brand-link .brand-link-inner.krups {
      border-top: 5px solid #f38230; }
    .featured-brands .brand-link .brand-link-inner.lagostina {
      border-top: 5px solid #D31522; }
    .featured-brands .brand-link .brand-link-inner.lg {
      border-top: 5px solid #A50034; }
    .featured-brands .brand-link .brand-link-inner.liftmaster {
      border-top: 5px solid #98002D; }
    .featured-brands .brand-link .brand-link-inner.maytag {
      border-top: 5px solid #002E5F; }
    .featured-brands .brand-link .brand-link-inner.moen {
      border-top: 5px solid #5b7f95; }
    .featured-brands .brand-link .brand-link-inner.nikon {
      border-top: 5px solid #ffe600; }
    .featured-brands .brand-link .brand-link-inner.nordictrack {
      border-top: 5px solid #000000; }
    .featured-brands .brand-link .brand-link-inner.norelco {
      border-top: 5px solid #0066a1; }
    .featured-brands .brand-link .brand-link-inner.olympus {
      border-top: 5px solid #07107A; }
    .featured-brands .brand-link .brand-link-inner.oreck {
      border-top: 5px solid #000000; }
    .featured-brands .brand-link .brand-link-inner.panasonic {
      border-top: 5px solid #0049AB; }
    .featured-brands .brand-link .brand-link-inner.philips {
      border-top: 5px solid #0066a1; }
    .featured-brands .brand-link .brand-link-inner.proform {
      border-top: 5px solid #EF4F34; }
    .featured-brands .brand-link .brand-link-inner.rowenta {
      border-top: 5px solid #009DC0; }
    .featured-brands .brand-link .brand-link-inner.saeco {
      border-top: 5px solid #de7510; }
    .featured-brands .brand-link .brand-link-inner.samsung {
      border-top: 5px solid #1428A0; }
    .featured-brands .brand-link .brand-link-inner.simplehuman {
      border-top: 5px solid #a87cb9; }
    .featured-brands .brand-link .brand-link-inner.sodastream {
      border-top: 5px solid #0D678F; }
    .featured-brands .brand-link .brand-link-inner.sonicare {
      border-top: 5px solid #0066a1; }
    .featured-brands .brand-link .brand-link-inner.sony {
      border-top: 5px solid #5865f5; }
    .featured-brands .brand-link .brand-link-inner.stanley {
      border-top: 5px solid #ffdb0a; }
    .featured-brands .brand-link .brand-link-inner.tfal {
      border-top: 5px solid #ff1027; }
    .featured-brands .brand-link .brand-link-inner.thermacell {
      border-top: 5px solid #06A7E2; }
    .featured-brands .brand-link .brand-link-inner.thermador {
      border-top: 5px solid #004164; }
    .featured-brands .brand-link .brand-link-inner.travelpro {
      border-top: 5px solid #015696; }
    .featured-brands .brand-link .brand-link-inner.weber {
      border-top: 5px solid #ed1c24; }
    .featured-brands .brand-link .brand-link-inner.whirlpool {
      border-top: 5px solid #EFB200; }
    .featured-brands .brand-link .brand-link-inner.xseries {
      border-top: 5px solid #1C1C1C; }
    .featured-brands .brand-link .brand-link-inner.register-app {
      border-top: 5px solid #f8991d; }
  .featured-brands .brand-link .brand-name {
    text-transform: uppercase;
    font-size: 1.25rem; }
  .featured-brands .brand-link img.brand-logo {
    filter: grayscale(1);
    transition: filter 0.25s;
    max-height: 100px; }
  .featured-brands .brand-link:hover img {
    filter: none;
    transition: filter 0.5s; }
  .featured-brands .brand-link:active {
    filter: none;
    -webkit-box-shadow: inset 0px 0px 0px 2px #374447;
    -moz-box-shadow: inset 0px 0px 0px 2px #374447;
    box-shadow: inset 0px 0px 0px 2px #5F696C;
    z-index: 1050; }

@media (max-width: 991.98px) {
  .featured-brands .aura-page-headers {
    background-image: none;
    padding-top: 3rem; }
  .featured-brands .register-more-header {
    padding: 0px 0px 12px 0px; }
    .featured-brands .register-more-header h1.headline {
      font-size: 2.5rem;
      margin: 0 auto;
      text-align: center; }
      .featured-brands .register-more-header h1.headline span.line-2 {
        font-size: 0.975rem;
        margin-left: 0px; }
    .featured-brands .register-more-header img {
      left: 257px;
      width: 24px; }
  .featured-brands .logo-link .logo-link-inner {
    padding: 1rem;
    min-height: 150px; }
  .featured-brands .logo-link img {
    filter: none; } }

.register-more .aura-footer {
  padding-top: 76px;
  padding-bottom: 76px;
  border: none;
  background-color: transparent; }

.register-more .aura-footer h4 {
  /*font-family: Montserrat-Medium;*/
  text-transform: uppercase;
  color: #00758D;
  letter-spacing: 2.4px; }

.register-more .aura-footer p {
  /*font-family: Montserrat-Regular;*/
  color: #00758D;
  letter-spacing: .7px;
  font-size: 14px; }

.register-more .aura-footer a {
  text-decoration: none;
  color: #00758D; }

.register-more .aura-footer a p:hover {
  color: #f8991d; }

.register-more .aura-footer img {
  width: 68px; }

.register-more .aura-footer i {
  font-size: 20px; }

.register-more .aura-footer hr {
  margin-top: 67px;
  margin-bottom: 67px;
  border-color: #AFB4B5; }

@media (max-width: 991.98px) {
  .register-more .aura-footer {
    padding-top: 45px;
    padding-bottom: 45px; }
  .register-more .aura-footer hr {
    margin-top: 45px;
    margin-bottom: 45px; } }

body {
  font-family: "Montserrat", "Open Sans", sans-serif; }

img.register-brand-logo {
  width: 20px; }

.register-more-breadcrumbs .list-inline-item {
  color: #374447; }

.register-more-breadcrumbs .active {
  font-weight: 700; }

@media (max-width: 991.98px) {
  .register-more-breadcrumbs .list-inline-item:not(.active) {
    display: none; } }

.info-header {
  min-height: 150px; }

.help-pages {
  /* ---------------- TYPOGRAPHY ----------------*/ }
  .help-pages .page-wrap {
    border: none; }
  .help-pages .header-mast-overlay {
    background-color: transparent; }
  .help-pages .aura-page-headers .page-header-title {
    text-transform: uppercase;
    font-size: 3rem;
    letter-spacing: 6px;
    color: #3f96b4;
    text-align: left; }
  .help-pages .aura-header-mast {
    background-size: cover;
    background-position: center;
    color: #fff;
    align-items: initial; }
  .help-pages .brand-logo-anchor {
    text-decoration: none; }
  .help-pages h1, .help-pages h2, .help-pages h3, .help-pages h4, .help-pages h5, .help-pages h6, .help-pages .h1, .help-pages .h2, .help-pages .h3, .help-pages .h4, .help-pages .h5, .help-pages .h6 {
    line-height: 1.4 !important; }
  .help-pages h4, .help-pages .h4 {
    /*font-family: Montserrat-Medium;*/
    font-size: 1.25rem;
    line-height: 30px;
    letter-spacing: .55px; }
  .help-pages p, .help-pages .p, .help-pages a {
    /*font-family: Montserrat-Regular;*/
    font-size: 1rem;
    line-height: 24px;
    letter-spacing: .4;
    color: #374447; }
  @media (max-width: 991.98px) {
    .help-pages .aura-page-headers {
      min-height: 0;
      padding: 2rem 1rem; }
    .help-pages .aura-page-headers .page-header-title {
      font-size: 2rem; }
    .help-pages h3, .help-pages .h3 {
      letter-spacing: .5px; }
    .help-pages h4, .help-pages .h4 {
      line-height: 24px;
      letter-spacing: .4px; } }

.product-brand-logo {
  max-width: 200px; }

.register-more {
  /* -------- TYPOGRAPHY --------*/ }
  .register-more .aura-header {
    border-bottom: 0; }
  .register-more header .navbar {
    background-color: #eff2f3;
    border-bottom: 4px solid #FFF; }
  .register-more header .navbar-brand img {
    height: 28px; }
  .register-more header .navbar i {
    color: #374447; }
  .register-more header .navbar-toggler.toggler-example {
    cursor: pointer; }
  .register-more header .navbar li a {
    color: #374447; }
  .register-more header .aura-container {
    box-shadow: none; }
  .register-more .register-more-breadcrumbs .list-inline-item {
    font-size: 0.875rem; }
  .register-more .footer {
    padding-top: 76px; }
  .register-more .brand-categories .btn.active, .register-more .brand-categories .survey-scale .custom-control.custom-radio .active.custom-control-label, .survey-scale .custom-control.custom-radio .register-more .brand-categories .active.custom-control-label, .register-more .brand-categories .markdown-body blockquote a.active, .markdown-body blockquote .register-more .brand-categories a.active {
    background-color: #f8991d !important;
    border-color: #f8991d !important; }
    .register-more .brand-categories .btn.active .badge, .register-more .brand-categories .survey-scale .custom-control.custom-radio .active.custom-control-label .badge, .survey-scale .custom-control.custom-radio .register-more .brand-categories .active.custom-control-label .badge, .register-more .brand-categories .markdown-body blockquote a.active .badge, .markdown-body blockquote .register-more .brand-categories a.active .badge {
      color: #f8991d !important; }
  .register-more h1, .register-more .h1 {
    font-weight: 700;
    font-size: 35px;
    letter-spacing: .75px; }
  .register-more h2, .register-more .h2 {
    font-weight: 400;
    font-size: 38px;
    line-height: 38px !important;
    letter-spacing: 1.6px;
    text-transform: uppercase; }
  .register-more h3, .register-more .h3 {
    font-weight: 400;
    font-size: 25px;
    line-height: 30px !important;
    letter-spacing: 1.45px;
    text-transform: uppercase; }
  .register-more h4, .register-more .h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: .55px; }
  .register-more h5, .register-more .h5 {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px !important;
    letter-spacing: .55px; }
  .register-more p, .register-more .p, .register-more a {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: .67; }
  .register-more p a {
    color: #00758D;
    text-decoration: underline; }
    .register-more p a:hover, .register-more p a:active {
      color: #f8991d; }

@media (max-width: 991.98px) {
  .breadcrumbs {
    padding-bottom: 30px; }
  .inactive {
    display: none; }
  .footer {
    padding-top: 45px; }
  .modelSerial {
    margin-top: 30px; }
  hr {
    margin-top: 30px;
    margin-bottom: 30px; }
  h1, .h1 {
    font-size: 30px;
    line-height: 48px !important;
    letter-spacing: 1.3px; }
  h2, .h2 {
    font-size: 26px;
    line-height: 40px !important;
    letter-spacing: 1px; }
  h3, .h3 {
    font-size: 20px;
    letter-spacing: .6px; }
  h5, .h5 {
    font-size: 18px; } }

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle; }
  .select2-container .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 28px;
    user-select: none;
    -webkit-user-select: none; }
    .select2-container .select2-selection--single .select2-selection__rendered {
      display: block;
      padding-left: 8px;
      padding-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .select2-container .select2-selection--single .select2-selection__clear {
      position: relative; }
  .select2-container .select2-selection--multiple {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 32px;
    user-select: none;
    -webkit-user-select: none; }
    .select2-container .select2-selection--multiple .select2-selection__rendered {
      display: inline-block;
      overflow: hidden;
      padding-left: 8px;
      text-overflow: ellipsis;
      white-space: nowrap; }
  .select2-container .select2-search--inline {
    float: left; }
    .select2-container .select2-search--inline .select2-search__field {
      box-sizing: border-box;
      border: none;
      font-size: 100%;
      margin-top: 5px;
      padding: 0; }
      .select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
        -webkit-appearance: none; }

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px; }

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051; }

.select2-results {
  display: block; }

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0; }

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none; }

.select2-results__option[aria-selected] {
  cursor: pointer; }

.select2-container--open .select2-dropdown {
  left: 0; }

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-search--dropdown {
  display: block;
  padding: 4px; }
  .select2-search--dropdown .select2-search__field {
    padding: 4px;
    width: 100%;
    box-sizing: border-box; }
    .select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
      -webkit-appearance: none; }
  .select2-search--dropdown.select2-search--hide {
    display: none; }

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0); }

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; }

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px; }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px; }
  .select2-container--default .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold; }
  .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #999; }
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px; }
    .select2-container--default .select2-selection--single .select2-selection__arrow b {
      border-color: #888 transparent transparent transparent;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      height: 0;
      left: 50%;
      margin-left: -4px;
      margin-top: -2px;
      position: absolute;
      top: 50%;
      width: 0; }

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default; }
  .select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
    display: none; }

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text; }
  .select2-container--default .select2-selection--multiple .select2-selection__rendered {
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0 5px;
    width: 100%; }
    .select2-container--default .select2-selection--multiple .select2-selection__rendered li {
      list-style: none; }
  .select2-container--default .select2-selection--multiple .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-top: 5px;
    margin-right: 10px;
    padding: 1px; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #999;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 2px; }
    .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
      color: #333; }

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0; }

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa; }

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield; }

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--default .select2-results__option[role=group] {
  padding: 0; }

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999; }

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd; }

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em; }
  .select2-container--default .select2-results__option .select2-results__option .select2-results__group {
    padding-left: 0; }
  .select2-container--default .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -1em;
    padding-left: 2em; }
    .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
      margin-left: -2em;
      padding-left: 3em; }
      .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
        margin-left: -3em;
        padding-left: 4em; }
        .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
          margin-left: -4em;
          padding-left: 5em; }
          .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
            margin-left: -5em;
            padding-left: 6em; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white; }

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right; }

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }
  .select2-container--classic .select2-selection--single:focus {
    border: 1px solid #5897fb; }
  .select2-container--classic .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px; }
  .select2-container--classic .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-right: 10px; }
  .select2-container--classic .select2-selection--single .select2-selection__placeholder {
    color: #999; }
  .select2-container--classic .select2-selection--single .select2-selection__arrow {
    background-color: #ddd;
    border: none;
    border-left: 1px solid #aaa;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px;
    background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
    background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
    background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0); }
    .select2-container--classic .select2-selection--single .select2-selection__arrow b {
      border-color: #888 transparent transparent transparent;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      height: 0;
      left: 50%;
      margin-left: -4px;
      margin-top: -2px;
      position: absolute;
      top: 50%;
      width: 0; }

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb; }
  .select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
    background: transparent;
    border: none; }
    .select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
      border-color: transparent transparent #888 transparent;
      border-width: 0 4px 5px 4px; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0); }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb; }

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0; }
  .select2-container--classic .select2-selection--multiple:focus {
    border: 1px solid #5897fb; }
  .select2-container--classic .select2-selection--multiple .select2-selection__rendered {
    list-style: none;
    margin: 0;
    padding: 0 5px; }
  .select2-container--classic .select2-selection--multiple .select2-selection__clear {
    display: none; }
  .select2-container--classic .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px; }
  .select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
    color: #888;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 2px; }
    .select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
      color: #555; }

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0; }

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none; }

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent; }

.select2-container--classic .select2-dropdown--above {
  border-bottom: none; }

.select2-container--classic .select2-dropdown--below {
  border-top: none; }

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--classic .select2-results__option[role=group] {
  padding: 0; }

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey; }

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white; }

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }
